import React, { useEffect, useState } from "react";
import { getLicencedPublisherList } from "../../utilities/constants";
import { DDRPagination } from "../DDRComponents/DDRPagination";
import PaginationComponent from "../DDRComponents/PublisherListPagination";

export const LicencedPublisherData = () => {
  const [page, setPage] = useState(1);
  const [publisherData, setPublisherData] = useState([]);
  const [numOfPages, setNumOfPages] = useState(0);

  useEffect(() => {
    try {
      const response = fetch(getLicencedPublisherList(page))
        .then((res) => res.json())
        .then((data) => {
          setPublisherData(data?.data[0][0]?.publisher_list);
          setNumOfPages(data?.data[0][0]?.no_of_records);
        });
    } catch (error) {
      console.log(error);
    }
  }, [page]);

  const handlePageChange = (pageNumber) => {
    setPage(pageNumber);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    // Fetch or filter data based on the current page
  };

  const totalPages = Math.ceil(numOfPages / 100);
  return (
    <>
      <div style={{ marginTop: "80px", marginLeft: "40px" }}>
        <h5>Licensed Publisher List</h5>
        <div>
          <table className="MoaSubscription table-bordered">
            <thead>
              <tr className=" contactPersondata">
                <th>Name of the Publisher</th>
              </tr>
            </thead>
            <tbody>
              {publisherData?.map((item, ind) => (
                <tr className="contactPersondata">
                  <td>
                    <div
                      id="title"
                      dangerouslySetInnerHTML={{
                        __html: item.publisher_name,
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <div style={{ marginBottom: "100px", marginTop: "20px" }}>
            <PaginationComponent
              totalPages
              numOfPages={totalPages}
              currentPage={page}
              setCurrentPage={setPage}
              tableDataReport={publisherData}
              recordsPerPage={100}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
        <br />
        {/* <div style={{ marginBottom: "200px" }}></div> */}
      </div>
    </>
  );
};

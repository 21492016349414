import React, { useState, useEffect } from "react";
import _ from "lodash";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import Radio from "../../atoms/Radio/Radio";
import InputField from "../../atoms/InputField/InputField";

import {
  lastFiveYears,
  IncomingRequest,
  DDRStatusOverview,
  getReceivedDDROverviewStatus,
  getConsortiaMemberList,
  radioDataValues,
  getSentDDROverviewStatus,
  OutgoingRequest,
  // getReceivedDDRList,
  getReceivedDDRListPageWise,
  getDDRLinkResolverLink,
} from "../../containers/DDR/constants";
import {
  exportReceivedDDRMngListUrl,
  formatedObject,
} from "../../containers/Reports/constants";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import Loading from "../../components/Loading/Loading";
import DDRArticleTable from "./DDRArticleTable";
import RequestWidget from "./RequestWidget";
import { Button } from "react-bootstrap";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import BasicSelectDropDown from "../../atoms/DropDown/BasicSelectDropDown";
import { baseUrl } from "../../utilities/constants";
// import { Pagination } from "../ReportsComponents/Pagination";
import ScrollTopArrow from "./ScrollTopArrow";
import ScrollBottomArrow from "./ScrollBottomArrow";
import {
  calculateToDate,
  formatedSelect,
  handleStatusChange,
} from "../../utilities/formatedSelect";
import { DDRPagination } from "./DDRPagination";
import DDRLoading from "../Loading/DDRLoading";

const DDRRecived = ({
  customerID,
  consortiamaster_id,
  priority,
  admin_type,
}) => {
  const [tableDataDDRR, setTableDataDDRR] = useState([]);
  const [loading, setLoading] = useState(false);
  const [incomingRequest, setIncomingRequest] = useState([]);
  const [outgoingRequest, setOutgoingRequest] = useState([]);
  const [isicoming, setincoming] = useState(true);
  const [isoutgoing, setIsOutgoing] = useState(true);
  const [outgoingYear, setoutgoingYear] = useState(new Date().getFullYear());
  const [incomingYear, seticomingYear] = useState(new Date().getFullYear());
  const [fromDatedata, setFromDatedata] = useState("");
  const [isFromdateLoad, setIsFromdateLoad] = useState(false);
  const [isFromdate, setIsFromdate] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const [numOfPages, setNumOfPages] = useState([]);
  const [numOfRecords, setNumOfRecords] = useState();
  const [currentStatus, setCurrentStatus] =useState()
  const member_id = "1";
  const [linkResolverData, setLinkResolverData] = useState([]);
  let currentyear = new Date().getFullYear();
  let currentDate = new Date();
  let currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, "0");
  let fromDateFormate = `${currentyear}-01-01`;
  const [data, setData] = useState({
    request_from_id: "0",
    ddr_status: "1",
    ddr_from_date: fromDatedata,
    ddr_to_date: formatDate(),
    export_type: "excel",
    ddr_priority: "0",
    ddr_id: "0",
  });

  useEffect(() => {
    incomingRequestCall(new Date().getFullYear());
    outgoingRequestCall(new Date().getFullYear());
  }, []);

  useEffect(() => {
    if (isFromdate) {
      handleLoad();
    }
  }, [isFromdate]);

  useEffect(() => {
    if (isFromdate) {
      handleLoadButton();
    }
  }, [currentPage, recordsPerPage]);

  useEffect(() => {
    fromDateResponse();
  }, [isFromdateLoad]);

  useEffect(() => {
    setData({
      request_from_id: data && data?.request_from_id,
      ddr_status: data && data?.ddr_status,
      // ddr_from_date: fromDatedata,
      ddr_from_date: fromDateFormate.toString(),
      ddr_to_date: formatDate(),
      export_type: data && data?.export_type,
      ddr_priority: data && data?.ddr_priority,
      ddr_id: data && data?.ddr_id,
    });
  }, [fromDatedata, isFromdateLoad]);

  useEffect(() => {
    goToTop();
  }, [recordsPerPage, currentPage]);

  const consortiaMemberList = useFetch(
    getConsortiaMemberList(consortiamaster_id),
    {}
  );

  const fromDateResponse = async () => {
    let customer_id;
    if (!isFromdateLoad) {
      customer_id = customerID;
    } else {
      customer_id = data["request_from_id"];
    }

    if (data["request_from_id"] === "") {
      customer_id = customerID;
    }
    await fetch(
      `${baseUrl}/api/get_client_wise_ddr_from_date/get_client_wise_ddr_from_date?customer_id=${customer_id}&member_id=${member_id}`
    )
      .then((response) => response.json())
      .then((val) => {
        setFromDatedata(val?.data[0][0]?.from_date);
        setIsFromdate(true);
      });
  };

  function formatDate() {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleInputchange = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    setData(_dat);
  };

  const handleInputchangeInstitute = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    setData(_dat);
    setIsFromdateLoad(!isFromdateLoad);
  };

  const linkToDisplay = (dataArray) => {
    if (!Array.isArray(dataArray)) return;
    let formData = new FormData();

    let data = dataArray?.map((obj) => {
      let object = {
        customer_id: obj?.customer_id,
        article_id: obj?.article_id,
        // consortia_virtualid: customerData?.consortia_virtualid,
        resource_type: obj?.resource_type_id,
        access_type: obj?.access_type_id,
        is_oa_article: obj?.is_oa_article,
        article_doi: obj?.article_doi,
        resourceissue_id: obj?.issue_id,
      };
      return object;
    });

    let formApiData = {
      data,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    fetch(getDDRLinkResolverLink, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.message === "Ok") {
          setLinkResolverData(result);
        } else {
          setLinkResolverData("");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
   

  const handleLoad = async () => {
    setLoading(true);
    setCurrentPage(1);
    
    const _dat = _.cloneDeep(data);
    _dat["customerId"] = customerID;
    _dat["consortia_id"] = consortiamaster_id;
    _dat["rpp"] = recordsPerPage;
    _dat["page_no"] = currentPage;

    _dat["ddr_id"] = "0";
    delete _dat.export_type;

    if (_dat["ddr_id"] === "") {
      _dat["ddr_id"] = "0";
    }
    if (_dat["ddr_priority"] === "") {
      _dat["ddr_priority"] = "0";
    }

    if (_dat["request_from_id"] === "") {
      _dat["request_from_id"] = "0";
    }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");
    const response = await fetchUrl(getReceivedDDRListPageWise(str));

    if (response?.message === "Ok") {
      linkToDisplay(response?.data[0][0]?.DDR_Received_List);
    }
    if (
      !!response &&
      !!response.data &&
      response.data[0] &&
      response.data[0][0] &&
      response.data[0][0].DDR_Received_List
    ) {
      setTableDataDDRR(response.data[0][0].DDR_Received_List);
      setNumOfPages(response.data[0][0].pagination);
      setNumOfRecords(response.data[0][0].no_of_records);
      handleStatusChange(_dat, setCurrentStatus)
      setLoading(false);
    }
    if (
      response &&
      response.data &&
      response.data[0][0].DDR_Received_List === null
    ) {
      setTableDataDDRR("");
      setNumOfRecords("")
      setLoading(false);
    }
  };

  const handleLoadButton = async () => {
    setLoading(true);
    const _dat = _.cloneDeep(data);
    _dat["customerId"] = customerID;
    _dat["consortia_id"] = consortiamaster_id;
    _dat["rpp"] = recordsPerPage;
    _dat["page_no"] = currentPage;

    _dat["ddr_id"] = "0";
    delete _dat.export_type;

    if (_dat["ddr_id"] === "") {
      _dat["ddr_id"] = "0";
    }
    if (_dat["ddr_priority"] === "") {
      _dat["ddr_priority"] = "0";
    }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    // const response = await fetchUrl(getReceivedDDRList(str));

    const response = await fetchUrl(getReceivedDDRListPageWise(str));
    if (response?.message === "Ok") {
      linkToDisplay(response?.data[0][0]?.DDR_Received_List);
    }

    if (
      !!response &&
      !!response.data &&
      response.data[0] &&
      response.data[0][0] &&
      response.data[0][0].DDR_Received_List
    ) {
      setTableDataDDRR(response.data[0][0].DDR_Received_List);
      setNumOfPages(response.data[0][0].pagination);
      handleStatusChange(_dat, setCurrentStatus)
      setLoading(false);
    }
    if (
      response &&
      response.data &&
      response.data[0][0].DDR_Received_List === null
    ) {
      setTableDataDDRR("");
      setLoading(false);
    }
  };

  const handleSearchIcon = async () => {
    setLoading(true);
    const _dat = _.cloneDeep(data);
    _dat["customerId"] = customerID;
    _dat["consortia_id"] = consortiamaster_id;
    _dat["rpp"] = recordsPerPage;
    _dat["page_no"] = currentPage;

    delete _dat.export_type;
    if (_dat["ddr_id"] === "") {
      _dat["ddr_id"] = "0";
    }
    if (_dat["ddr_priority"] === "") {
      _dat["ddr_priority"] = "0";
    }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    // const response = await fetchUrl(getReceivedDDRList(str));
    const response = await fetchUrl(getReceivedDDRListPageWise(str));
    if (response?.message === "Ok") {
      linkToDisplay(response?.data[0][0]?.DDR_Received_List);
    }

    if (
      !!response &&
      !!response.data &&
      response.data[0] &&
      response.data[0][0] &&
      response.data[0][0].DDR_Received_List
    ) {
      setTableDataDDRR(response.data[0][0].DDR_Received_List);
      setNumOfPages(response.data[0][0].pagination);
      setNumOfRecords(response.data[0][0].no_of_records);
      handleStatusChange(_dat, setCurrentStatus)
      setLoading(false);
    }

    if (
      response &&
      response.data &&
      response.data[0][0].DDR_Received_List === null
    ) {
      setTableDataDDRR("");
      setNumOfRecords("")
      setLoading(false);
    }
  };

  const handleExport = () => {
    const _dat = _.cloneDeep(data);
    _dat["customerId"] = customerID;
    _dat["consortia_id"] = consortiamaster_id;

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");
    window.open(exportReceivedDDRMngListUrl(str));
  };

  const incomingRequestCall = async (year) => {
    const response = await fetchUrl(
      getReceivedDDROverviewStatus(customerID, year)
    );
    setIncomingRequest(response.data);
    setincoming(false);
  };

  const outgoingRequestCall = async (year) => {
    const response = await fetchUrl(getSentDDROverviewStatus(customerID, year));
    setOutgoingRequest(response.data);
    setIsOutgoing(false);
  };

  const yearChanged = (value, name) => {
    if (name === "incomingRequest_year") {
      setincoming(true);
      incomingRequestCall(value);
      seticomingYear(value);
    } else {
      setIsOutgoing(true);
      outgoingRequestCall(value);
      setoutgoingYear(value);
    }
  };

  const handleFromDateChange = (value) => {
    const _dat = _.cloneDeep(data);
    _dat["ddr_from_date"] = value;

    const fromDate = new Date(value);
    const toDate = new Date(fromDate);
    toDate.setDate(fromDate.getDate() + 365);

    const today = new Date();
    if (toDate > today) {
      _dat["ddr_to_date"] = formatDate(today);
    } else {
      _dat["ddr_to_date"] = toDate.toISOString().split("T")[0];
    }

    setData(_dat);
  };

  return (
    <div className="DDRRecived">
      <CommonHeading heading="DDR Received" />
      <ScrollBottomArrow />

      <div className="row">
        <div className="col-md-6">
          {isicoming && <Loading />}
          {!isicoming && incomingRequest && (
            <RequestWidget
              commonheading={DDRStatusOverview}
              yearData={lastFiveYears()}
              name="incomingRequest"
              heading={IncomingRequest}
              data={incomingRequest}
              classname="DDRStatusOverviewRow"
              yearChanged={yearChanged}
              year={incomingYear}
            />
          )}
        </div>
        <div className="col-md-6">
          {isoutgoing && <Loading />}
          {!isoutgoing && outgoingRequest && (
            <RequestWidget
              yearData={lastFiveYears()}
              heading={OutgoingRequest}
              name="outgoingRequest"
              data={outgoingRequest}
              classname="DDRStatusOverviewRowRight"
              yearChanged={yearChanged}
              year={outgoingYear}
            />
          )}
        </div>
      </div>

      <div className=" DDRRExportRow">
        <Radio
          label={"Excel"}
          value={"excel"}
          name="export_type"
          checkedValue={data["export_type"]}
          handleInputchange={handleInputchange}
        />
        <Radio
          label={"CSV"}
          value={"csv"}
          name="export_type"
          checkedValue={data["export_type"]}
          handleInputchange={handleInputchange}
        />

        <Button className=" contactPersondata" onClick={handleExport}>
          Export
        </Button>
      </div>

      <div className="DDRRecivedcontrolRows">
        <div className="DDRRecivedRadioRow">
          {Object.keys(radioDataValues).map((key, index) => (
            <Radio
              label={key}
              value={radioDataValues[key]}
              name="ddr_status"
              checkedValue={data["ddr_status"]}
              handleInputchange={handleInputchange}
              key={index}
            />
          ))}
        </div>
      </div>

      <div className="DDRRecivedInstutionRow mt-3">
        <div className="row">
          <span style={{ color: "red" }}>
            <strong>
              The selected date must be within 12-months or 1-year range.
            </strong>
          </span>
          <div className="col-md-12 d-flex">
            <div className="common-select-space w-300">
              {consortiaMemberList.isLoading && <Loading />}
              {!consortiaMemberList.isLoading &&
                consortiaMemberList.response &&
                !!consortiaMemberList.response.data && (
                  <BasicSelectDropDown
                    defaultSelectValue={data["request_from_id"]}
                    data={formatedObject(
                      consortiaMemberList &&
                        consortiaMemberList?.response &&
                        consortiaMemberList?.response?.data
                    )}
                    name="request_from_id"
                    label="Institution"
                    handleInputchange={handleInputchangeInstitute}
                  />
                )}
            </div>
            <div>
              <InputField
                placeholder={"From date"}
                name={"ddr_from_date"}
                type="date"
                value={data["ddr_from_date"] || ""}
                // handleInputchange={handleInputchange}
                handleInputchange={handleFromDateChange}
                classname={`w-200 ddr_from_date`}
                // min={fromDatedata}
                //max={data["ddr_to_date"]}
                max={formatDate()}
              />
            </div>
            <div>
              <InputField
                placeholder={"To date"}
                name={"ddr_to_date"}
                type="date"
                value={data["ddr_to_date"] || ""}
                handleInputchange={handleInputchange}
                classname={`w-200 ddr_to_date`}
                // max={formatDate()}
                // min={data["ddr_from_date"]}
                //max={data["ddr_to_date"]}
                min={data["ddr_from_date"]}
                max={calculateToDate(data["ddr_from_date"])}
              />
            </div>
            <div style={{ marginTop: "25px" }}>
              <Button className="contactPersondata" onClick={handleLoad}>
                Load
              </Button>
            </div>
          </div>
        </div>
        <div className="row">
          {/* <div className="col-md-12 d-flex ddr_contnt"> */}
          {/* <div className="institute_pisition">
              <div className="common-select-space inline w-200">
                <BasicSelectDropDown
                  defaultSelectValue={data["ddr_priority"]}
                  data={formatedSelect(priority?.data)}
                  label="Priority"
                  name="ddr_priority"
                  handleInputchange={handleInputchange}
                />
              </div>
              <div className="common-select-space inline">
                <Button className="contactPersondata" onClick={handleLoad}>
                  Load
                </Button>
              </div>
            </div> */}
          <div className="col-md-12 d-flex ddr_contnt justify-content-end consortia_ddr_mrg">
            <div>
              <InputField
                placeholder={"Search DDR ID"}
                name={"ddr_id"}
                handleInputchange={handleInputchange}
                classname={`w-200 ddr_id`}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSearchIcon();
                  }
                }}
              />
              <i
                className="fa fa-search ddr_search_icn"
                onClick={handleSearchIcon}
              ></i>
            </div>
          </div>
        </div>
      </div>

      <div>
        {loading && <DDRLoading />}
        <DDRArticleTable
          tableData={tableDataDDRR && tableDataDDRR}
          customerID={customerID}
          currentPage={currentPage}
          recordsPerPage={recordsPerPage}
          linkResolverData={linkResolverData}
          admin_type={admin_type}
          numOfRecords={numOfRecords}
          currentStatus={currentStatus}
        />
        <div>
          <DDRPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setRecordsPerPage={setRecordsPerPage}
            tableDataReport={tableDataDDRR && tableDataDDRR}
            recordsPerPage={recordsPerPage}
            numOfPages={numOfPages}
          />
        </div>
      </div>
      <div className="ScrollTopArrow">
        <ScrollTopArrow />
      </div>
    </div>
  );
};

export default DDRRecived;

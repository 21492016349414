import React from "react";
import { Container } from "react-bootstrap";
import "./Footer.css";
// faq associated with the screen
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
export const FAQ = () => {
  return (
    <div className="main_about">
      {/* <Container>
        <div>
          <h5 className="headermargin">FAQ: Frequently Asked Questions</h5>
          <h5>
            J-Gate help desk received a number of queries frequently asked by
            customers. As a service to other customers who may have similar
            questions, answers to these FAQs are provided below.
          </h5>
        </div>
        <br />

        <div>
          <h5>I. General information</h5>
          <ul>
            <div>
              <a href="#What is J-Gate">1. What is J-Gate ?</a>
            </div>
            <div>
              <a href="#Who should use J-Gate">2. Who should use J-Gate ?</a>
            </div>
            <div>
              <a href="#How often is the J-Gate database updated?">
                3. How often is the J-Gate database updated?
              </a>
            </div>
            <div>
              <a href="#How latest is the content on J-Gate when compared to publisher's website?">
                4. How latest is the content on J-Gate when compared to
                publisher's website ?
              </a>
            </div>

            <div>
              <a href="#What types of journals are included in J-Gate?">
                5. What types of journals are included in J-Gate?
              </a>
            </div>
            <div>
              <a href="#How does J-Gate mark a journal as peer reviewed?">
                6. How does J-Gate mark a journal as peer reviewed ?
              </a>
            </div>
            <div>
              <a href="#What are the types of Open Access Journals included in J-Gate?">
                7. What are the types of Open Access Journals included in J-Gate
                ?
              </a>
            </div>
            <div>
              <a href="#Are the journals subject classified using Library of Congress or Dewey Decimal classification or any standards subject headings?">
                8. Are the journals subject classified using Library of Congress
                or Dewey Decimal classification or any standards subject
                headings ?
              </a>
            </div>
            <div>
              <a href="#Whom do I contact to get a journal included in J-Gate?">
                9. Whom do I contact to get a journal included in J-Gate ?{" "}
              </a>
            </div>
            <div>
              <a href="#Where do I get user instructions/help?">
                10. Where do I get user instructions/help?{" "}
              </a>
            </div>
            <div>
              <a href="#How can I request for Trial access?">
                11. How can I request for Trial access ?
              </a>
            </div>
          </ul>
        </div>
        <div>
          <h5>II. J-Gate Coverage Statistics</h5>
          <ul>
            <div>
              <a href="#How many journals are there in the portal?">
                1. How many journals are there in the portal ?{" "}
              </a>
            </div>
            <div>
              <a href="#How many are peer reviewed journals?">
                2. How many are peer reviewed journals ?{" "}
              </a>
            </div>
            <div>
              <a href="#How many Open Access Journals are there in J-Gate">
                3. How many Open Access Journals are there in J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#How many Open access Peer Reviewed Journals are in there in J-Gate?">
                4. How many Open access Peer Reviewed Journals are in there in
                J-Gate?
              </a>
            </div>
            <div>
              <a href="#How many Indian journals?">
                5. How many Indian journals?{" "}
              </a>
            </div>
            <div>
              <a href="#How many Indian peer reviewed journals?">
                6. How many Indian peer reviewed journals?{" "}
              </a>
            </div>
            <div>
              <a href="#How many publishers are indexed in J-Gate?">
                7. How many publishers are indexed in J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#How many articles are indexed in J-Gate?">
                8. How many articles are indexed in J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#How many open access articles are indexed in J-Gate?">
                9. How many open access articles are indexed in J-Gate?{" "}
              </a>
            </div>
          </ul>
        </div>
        <div>
          <h5>III. Features & Functionalities & Access related</h5>
          <ul>
            <div>
              <a href="#What features are available in J-Gate?">
                1. What features are available in J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#How to register for getting regular Alerts?">
                2. How to register for getting regular Alerts?{" "}
              </a>
            </div>
            <div>
              <a href="#Can I email articles using J-Gate?">
                3. Can I email articles using J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#Can I download articles from the J-Gate site?">
                4. Can I download articles from the J-Gate site?{" "}
              </a>
            </div>
            <div>
              <a href="#How to search articles? What is the difference between Quick Search & Advance Search">
                5. How to search articles? What is the difference between Quick
                Search & Advance Search{" "}
              </a>
            </div>
            <div>
              <a href="#How to access full text articles which are not subscribed by my institution but available in the portal?">
                6. How to access full text articles which are not subscribed by
                my institution but available in the portal?{" "}
              </a>
            </div>
            <div>
              <a href="#Can I access the table of contents for all the journals subscribed by my library">
                7. Can I access the table of contents for all the journals
                subscribed by my library{" "}
              </a>
            </div>
            <div>
              <a href="#I have access to J-Gate portal, but not able to access fulltext at publisher site">
                8. I have access to J-Gate portal, but not able to access
                fulltext at publisher site{" "}
              </a>
            </div>
          </ul>
        </div>
        <div>
          <h5>IV. Subscription</h5>
          <ul>
            <div>
              <a href="#How do I subscribe to J-Gate?">
                1. How do I subscribe to J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#I have subscribed to J-Gate, but am not able to access the product?">
                2. I have subscribed to J-Gate, but am not able to access the
                product?
              </a>
            </div>
            <div>
              <a href="#Is access to J-Gate through IP or by Username/Password?">
                3. Is access to J-Gate through IP or by Username/Password?{" "}
              </a>
            </div>
            <div>
              <a href="#Do you offer product training and how do I request for the same?">
                4. Do you offer product training and how do I request for the
                same?{" "}
              </a>
            </div>
          </ul>
        </div>
        <div>
          <h5>V. Technical Questions</h5>
          <ul>
            <div>
              <a href="#Does J-Gate provide technical support?">
                1. Does J-Gate provide technical support?{" "}
              </a>
            </div>
            <div>
              <a href="#Do you charge for technical support?">
                2. Do you charge for technical support?{" "}
              </a>
            </div>
            <div>
              <a href="#Browser compatibility">3. Browser compatibility </a>
            </div>
          </ul>
        </div>
        <div>
          <h5>VI. For Publishers</h5>
          <ul>
            <div>
              <a href="#How do I get my journal indexed in J-Gate?">
                1. How do I get my journal indexed in J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#What are the criteria for including a journal in J-Gate?">
                2. What are the criteria for including a journal in J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#Do you charge for indexing my journal in J-Gate?">
                3. Do you charge for indexing my journal in J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#How do I make changes or corrections to my Journals listed in J-Gate?">
                4. How do I make changes or corrections to my Journals listed in
                J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#Do you provide usage reports?">
                5. Do you provide usage reports?{" "}
              </a>
            </div>
          </ul>
        </div>
        <div>
          <h5>VII. For Librarians</h5>
          <ul>
            <div>
              <a href="#Do you provide usage reports?">
                1. Do you provide usage reports?{" "}
              </a>
            </div>
            <div>
              <a href="#Who will configure & host my library journals & their holdings in J-Gate?">
                2. Who will configure & host my library journals & their
                holdings in J-Gate?{" "}
              </a>
            </div>
            <div>
              <a href="#Can I customize the home page using my institutional logo & name?">
                3. Can I customize the home page using my institutional logo &
                name?{" "}
              </a>
            </div>
            <div>
              <a href="#Can I include my print titles">
                4. Can I include my print titles ?{" "}
              </a>
            </div>
          </ul>
        </div>
        <div>
          <h5>I. General information</h5>

          <div>
            <h5>
              1. What is J-Gate?
              <a href=" " name="What is J-Gate">
                &nbsp;
              </a>
            </h5>
            <p>
              J-Gate is an electronic gateway to global e-journal literature.
              Launched in 2001 by Informatics India Limited, J-Gate provides
              seamless access to millions of journal articles available online
              offered by Publishers. It presently has a massive database of
              journal literature, indexed from e-journals with links to full
              text at publisher sites. J-Gate also plans to support online
              subscription to journals, electronic document delivery, archiving
              and other related services.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              2. Who should use J-Gate?{" "}
              <a href=" " name="Who should use J-Gate">
                &nbsp;
              </a>
            </h5>
            <p>
              Libraries, Students and faculties of Colleges and universities,
              scientists, engineers, practitioners and researchers of R&D,
              industries, corporate etc use J-Gate for their educational and
              research needs and to know the latest developments in their
              respective area of interest.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              3. How often is the J-Gate database updated?
              <a href=" " name="How often is the J-Gate database updated?">
                &nbsp;
              </a>
            </h5>
            <p>
              J-Gate database is updated on a daily basis to ensure that you
              have immediate access to the latest content.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              4. How latest is the content on J-Gate when compared to
              publisher's website?
              <a
                href=" "
                name="How latest is the content on J-Gate when compared to publisher's website?"
              >
                &nbsp;
              </a>
            </h5>
            <p>
              There is zero to two day lag in content when compared to
              publisher's website.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              5. What types of journals are included in J-Gate?
              <a href=" " name="What types of journals are included in J-Gate?">
                &nbsp;
              </a>
            </h5>
            <p>
              J-Gate primarily indexes journals which are scientific and
              scholarly, it also includes professional & industry journals.
              Journals indexed in J-Gate should have full text articles and
              there is no subject restriction for inclusion. J-Gate covers
              journals published in English or at least Table of Contents and
              abstracts are in English. J-Gate indexes both open access and non
              open access journals.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              6. How does J-Gate mark a journal as peer reviewed?
              <a
                href=" "
                name="How does J-Gate mark a journal as peer reviewed?"
              >
                &nbsp;
              </a>
            </h5>
            <p>
              J-Gate flags a journal as peer reviewed if only the same is
              indicated by the publishers on their websites
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              7. What are the types of Open Access Journals included in J-Gate?
              <a
                href=" "
                name="What are the types of Open Access Journals included in J-Gate?"
              >
                &nbsp;
              </a>
            </h5>
            <p>
              Currently J-Gate marks only those journals as open access which is
              free. It excludes those Open Access journals which require online
              registration.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              8. Are the journals subject classified using Library of Congress
              or Dewey Decimal classification or any standards subject headings?
              <a
                href=" "
                name="Are the journals subject classified using Library of Congress or Dewey Decimal classification or any standards subject headings?"
              >
                &nbsp;
              </a>
            </h5>
            <p>
              No, J-Gate uses a proprietary subject classification system to
              classify journals.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              9. Whom do I contact to get a journal included in J-Gate?
              <a
                href=" "
                name="Whom do I contact to get a journal included in J-Gate?"
              >
                &nbsp;
              </a>
            </h5>

            <p>
              To get your journal indexed in J-Gate, please send an email with
              the name of the journal and its URL to{" "}
              <a href="mailto:jgatelicensing@informaticsglobal.com">
                jgatelicensing@informaticsglobal.com
              </a>
            </p>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              10. Where do I get user instructions/help?
              <a href=" " name="Where do I get user instructions/help?">
                &nbsp;
              </a>
            </h5>
            <p>
              a. Context sensitive online help: The help button is located on
              the top navigation bar on all screens of J-Gate .
            </p>
            <p>
              b. Online help facility: By clicking 'On Line Support' button
              provided on the bottom navigation bar, you will get help from one
              of our technical staff to resolve your queries.
            </p>

            <p>
              c. If you require further technical assistance please contact
              <a href="mailto:jgatelicensing@informaticsglobal.ai">
                jgatelicensing@informaticsglobal.ai
              </a>
            </p>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              11. How can I request for Trial access?
              <a href=" " name="How can I request for Trial access?">
                &nbsp;
              </a>
            </h5>
            <p>
              Please send an email to{" "}
              <a href="mailto:jgatelicensing@informaticsglobal.ai">
                jgatelicensing@informaticsglobal.ai
              </a>{" "}
              with your Name, Institution/Organization Name & address along with
              your email id.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
        </div>

        <div>
          <h5>II. J-Gate Coverage Statistics</h5>
          <div>
            <h5>
              1. How many journals are there in the portal?
              <a href=" " name="How many journals are there in the portal?">
                &nbsp;
              </a>
            </h5>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              2. How many are peer reviewed journals?
              <a href=" " name="How many are peer reviewed journals?">
                &nbsp;
              </a>
            </h5>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              3. How many Open Access Journals are there in J-Gate?
              <a
                href=" "
                name="How many Open Access Journals are there in J-Gate"
              >
                &nbsp;
              </a>
            </h5>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            {" "}
            <h5>
              4. How many Open access Peer Reviewed Journals are in there in
              J-Gate?
              <a
                href=" "
                name="How many Open access Peer Reviewed Journals are in there in J-Gate?"
              >
                &nbsp;
              </a>
            </h5>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              5. How many Indian journals?
              <a href=" " name="How many Indian journals?">
                &nbsp;
              </a>
            </h5>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              6. How many Indian peer reviewed journals?
              <a href=" " name="How many Indian peer reviewed journals?">
                &nbsp;
              </a>
            </h5>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              7. How many publishers are indexed in J-Gate?
              <a href=" " name="How many publishers are indexed in J-Gate?">
                &nbsp;
              </a>
            </h5>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              8. How many articles are indexed in J-Gate?
              <a href=" " name="How many articles are indexed in J-Gate?">
                &nbsp;
              </a>
            </h5>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              9. How many open access articles are indexed in J-Gate?
              <a
                href=" "
                name="How many open access articles are indexed in J-Gate?"
              >
                &nbsp;
              </a>
            </h5>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
        </div>
        <div>
          <h5>III. Features & Functionalities & Access related</h5>
          <div>
            <h5>
              1. What features are available in J-Gate?
              <a href=" " name="What features are available in J-Gate?">
                &nbsp;
              </a>
            </h5>
            <p>The following features are available in J-Gate</p>
            <p>a. Basic search</p>
            <p>b. Advance Search</p>
            <p>c. Journal finder</p>
            <p>d. Author finder</p>
            <p>e. Alert services</p>
            <p>f. Rights & access management system</p>
            <p>g. Provision to filtering search results</p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              2. How to register for getting regular Alerts?
              <a href=" " name="How to register for getting regular Alerts?">
                &nbsp;
              </a>
            </h5>
            <p>
              Click on the 'Alerts' button provided on the top navigation bar
              and register by giving your name & email_id. After registration,
              please select the journals for which you are interested in getting
              alerts and save the profile. Upon saving your profile, you will
              get regular alerts.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              3. Can I email articles using J-Gate?
              <a href=" " name="Can I email articles using J-Gate?">
                &nbsp;
              </a>
            </h5>
            <p>
              Yes, you can email the articles retrieved and selected from
              J-Gate.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              4. Can I download articles from the J-Gate site?
              <a href=" " name="Can I download articles from the J-Gate site?">
                &nbsp;
              </a>
            </h5>
            <p>
              Yes, you can download articles from J-Gate from all open access
              journals and the journals subscribed by your library.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              5. How to search articles? What is the difference between Quick
              Search & Advance Search
              <a
                href=" "
                name="How to search articles? What is the difference between Quick Search & Advance Search"
              >
                &nbsp;
              </a>
            </h5>
            <p>
              To search articles, J-Gate Currently provides both Basic Search
              and Advance Search modes
            </p>
            <p>
              (i) Basic Search, meant for starters/novices, can be used by
              typing in search box or by picking autosuggest keywords. These
              keywords are searched in Title, Abstract, and Keywords of the
              articles. You can do Boolean search or exact phrase search and
              also limit your search results for the following parameters
            </p>
            <p>a. subjects</p>
            <p>b. Peer reviewed journals</p>
            <p>c. Professional & industry journals</p>
            <p>d. Open access journals</p>
            <p>e. Indian journals</p>
            <p>
              (ii) In Advance Search, meant for by experienced users. You can do
              lot of combination searches and restrict your search to any of the
              parameters.
            </p>
            <p>
              a. Pick and choose the field(s) provided in the dropdown menu like
              Article Title, Author, Author affiliation,
              Title/Keywords/Abstract, Title/Keywords etc.
            </p>
            <p>b. Perform Boolean searches</p>
            <p>c. Perform exact phrase search</p>
            <p>d. Nested logic search</p>
            <p>e. You can limit your search results within a wide range like</p>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              6. How to access full text articles which are not subscribed by my
              institution but available in the portal?
              <a
                href=" "
                name="How to access full text articles which are not subscribed by my institution but available in the portal?"
              >
                &nbsp;
              </a>
            </h5>
            <p>
              To access the full text articles which are not subscribed by your
              institution, you can either send an email to the author using the
              author email id provided or you can write to one of the
              institutions listed against the article under 'Find-in-a-Library'.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              7. Can I access the table of contents for all the journals
              subscribed by my library
              <a
                href=" "
                name="Can I access the table of contents for all the journals subscribed by my library"
              >
                &nbsp;
              </a>
            </h5>
            <p>
              Yes, you can access the table of contents for all the journals
              subscribed by your library through J-Gate.
            </p>
            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>
              8. I have access to J-Gate portal, but not able to access fulltext
              at publisher site
              <a
                href=" "
                name="I have access to J-Gate portal, but not able to access fulltext at publisher site"
              >
                &nbsp;
              </a>
            </h5>

            <p>
              [<a href="#top">Top</a>]
            </p>
          </div>
          <div>
            <h5>IV. Subscription</h5>
            <div>
              <h5>
                1. How do I subscribe to J-Gate?
                <a href=" " name="How do I subscribe to J-Gate?">
                  &nbsp;
                </a>
              </h5>

              <p>
                You can either call +91 80 40387777 (Extn: 1013) or email to
                <a href="mailto:info@informaticsglobal.com">
                  info@informaticsglobal.com
                </a>{" "}
                to request for detailed pricing information, with your name &
                institution/organization name & address. Our regional
                representative will get in touch with you.
              </p>
              <p>
                [<a href="#top">Top</a>]
              </p>
            </div>
            <div>
              <h5>
                2. I have subscribed to J-Gate, but am not able to access the
                product?
                <a
                  href=" "
                  name="I have subscribed to J-Gate, but am not able to access the product?"
                >
                  &nbsp;
                </a>
              </h5>

              <p>
                Your institution access details would have changed. You may
                please contact{" "}
                <a href="mailto:jgatelicensing@informaticsglobal.ai">
                  jgatelicensing@informaticsglobal.ai
                </a>
                .
              </p>

              <p>
                [<a href="#top">Top</a>]
              </p>
            </div>
            <div>
              <h5>
                3. Is access to J-Gate through IP or by Username/Password?
                <a
                  href=" "
                  name="Is access to J-Gate through IP or by Username/Password?"
                >
                  &nbsp;
                </a>
              </h5>
              <p>
                J-Gate provides access as requested by the clients, either IP or
                username/Password or both.
              </p>
              <p>
                [<a href="#top">Top</a>]
              </p>
            </div>
            <div>
              {" "}
              <h5>
                4. Do you offer product training and how do I request for the
                same?
                <a
                  href=" "
                  name="Do you offer product training and how do I request for the same?"
                >
                  &nbsp;
                </a>
              </h5>
              <p>
                Yes, we do offer product training; please send in your request
                institution/organization name & address. Our training staff will
                get in touch with you to discuss the date and other details
                within 2 working days from the date of receipt of request.
              </p>
              <p>
                [<a href="#top">Top</a>]
              </p>
            </div>
          </div>
          <div>
            <div>
              <h5>V. Technical Questions</h5>
              <div>
                <h5>
                  1. Does J-Gate provide technical support?
                  <a href=" " name="Does J-Gate provide technical support?">
                    &nbsp;
                  </a>
                </h5>

                <p>
                  Yes, J-Gate provides technical support. You can either call
                  +91 80 40387777 (Extn: 1010) or email to{" "}
                  <a href="mailto:jgatelicensing@informaticsglobal.ai">
                    jgatelicensing@informaticsglobal.ai
                  </a>
                  . An online help resource is available between 9AM to 5PM on
                  all working days. Please feel free to come online and chat
                  with one of our service representatives who would be glad to
                  furnish you with the information you seek.
                </p>

                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
              <div>
                <h5>
                  2. Do you charge for technical support?
                  <a href=" " name="Do you charge for technical support?">
                    &nbsp;
                  </a>
                </h5>
                <p>
                  No, technical support is provided for free to all J-Gate
                  subscribers via email or telephone.
                </p>
                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
              <div>
                <h5>
                  3. Browser compatibility
                  <a href=" " name="Browser compatibility">
                    &nbsp;
                  </a>
                </h5>
                <p>For best user experience, we recommend the following:</p>
                <p>(i) Set display resolution to 1024 x 768 or higher</p>
                <p>(ii) Use IE9+, Firefox 16+, Chrome 22+</p>
                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
            </div>
            <div>
              <h5>VI. For Publishers</h5>
              <div>
                <h5>
                  1. How do I get my journal indexed in J-Gate?
                  <a href=" " name="How do I get my journal indexed in J-Gate?">
                    &nbsp;
                  </a>
                </h5>

                <p>
                  To get your journal indexed in J-Gate, please send an email
                  with the title of the journal and its URL to
                  <a href="mailto:jgatelicensing@informaticsglobal.com">
                    jgatelicensing@informaticsglobal.com
                  </a>
                  .
                </p>

                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
              <div>
                {" "}
                <h5>
                  2. What are the criteria for including a journal in J-Gate?
                  <a
                    href=" "
                    name="What are the criteria for including a journal in J-Gate?"
                  >
                    &nbsp;
                  </a>
                </h5>
                <p>
                  The criterion to index a journal in J-Gate is that it should
                  be available online and at least Table of contents and
                  abstracts should be in English.
                </p>
                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
              <div>
                <h5>
                  3. Do you charge for indexing my journal in J-Gate?
                  <a
                    href=" "
                    name="Do you charge for indexing my journal in J-Gate?"
                  >
                    &nbsp;
                  </a>
                </h5>
                <p>
                  No, we do not charge for indexing journal in J-Gate. We
                  appreciate publisher participation in this process by
                  regularly providing us updates.
                </p>
                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
              <div>
                {" "}
                <h5>
                  4. How do I make changes or corrections to my Journals listed
                  in J-Gate?
                  <a
                    href=" "
                    name="How do I make changes or corrections to my Journals listed in J-Gate?"
                  >
                    &nbsp;
                  </a>
                </h5>
                <p>
                  Please send an email with details to be corrected to
                  <a href="mailto:jgatelicensing@informaticsglobal.com">
                    jgatelicensing@informaticsglobal.com
                  </a>
                </p>
                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
              <div>
                {" "}
                <h5>
                  5. Do you provide usage reports?
                  <a href=" " name="Do you provide usage reports?">
                    &nbsp;
                  </a>
                </h5>
                <p>Yes, COUNTER usage reports are provided</p>
                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
            </div>
            <div>
              <h5>VII. For Librarians</h5>
              <div>
                <h5>
                  1. Do you provide usage reports?
                  <a href=" " name="Do you provide usage reports?">
                    &nbsp;
                  </a>
                </h5>
                <p>Yes, COUNTER 4 compliant usage reports are provided</p>
                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
              <div>
                {" "}
                <h5>
                  2. Who will configure & host my library journals & their
                  holdings in J-Gate?
                  <a
                    href=" "
                    name="Who will configure & host my library journals & their holdings in J-Gate?"
                  >
                    &nbsp;
                  </a>
                </h5>
                <p>
                  As a one-time support we will offer you our services in
                  configuring your library's journal collection. Subsequent
                  updating will have to be done online by you for which we are
                  providing tools through admin login.
                </p>
                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
              <div>
                {" "}
                <h5>
                  3. Can I customize the home page using my institutional logo &
                  name?
                  <a
                    href=" "
                    name="Can I customize the home page using my institutional logo & name?"
                  >
                    &nbsp;
                  </a>
                </h5>
                <p>
                  Yes, your home page can be customized using your institutional
                  logo & name.
                </p>
                <p>
                  [<a href="#top">Top</a>]
                </p>
              </div>
              <div>
                {" "}
                <h5>
                  4. Can I include my print titles?
                  <a href=" " name="Can I include my print titles">
                    &nbsp;
                  </a>
                </h5>
                <p>
                  Yes, you can include & configure print journals subscribed by
                  your library in J-Gate, provided these are listed & indexed in
                  J-Gate.
                </p>
                <p className="pe-5 footer_extra_margin">
                  [<a href="#top">Top</a>]
                </p>
              </div>
            </div>
          </div>
        </div>
      </Container> */}

      <Container>
        <div style={{ fontSize: "20px", fontWeight: 700, color: "#0d6efd" }}>
          {" "}
          FAQ: Frequently Asked Questions
        </div>
        <div
          style={{
            marginBottom: "10px",
            fontWeight: 500,
            fontSize: "16px",
            color: "gray",
            listStyle: "none",
          }}
        >
          J-Gate help desk received a number of queries frequently asked by
          customers. As a service to other customers who may have similar
          questions, answers to these FAQs are provided below.
        </div>
        <div style={{ marginTop: "20px" }}>
          <Tab.Container id="left-tabs-example" defaultActiveKey="1">
            <Row>
              <Col sm={3}>
                <Nav variant="pills" className="flex-column tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="1">General Information</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="2">J-Gate Coverage Statistics</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3">
                      Features & Functionalities & Access related
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4">Subscription</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="5">Technical Questions</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="6">For Publishers</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="7">For Librarians</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="8">Frequently Asked Questions</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 700,
                        fontSize: "20px",
                        listStyle: "none",
                        color: "#0d6efd",
                      }}
                    >
                      General Information
                    </div>
                    <div className="subHeadingText">What is J-Gate?</div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      J-Gate functions as a electronic gateway access to a vast
                      array of e-journal content worldwide. Initially introduced
                      in 2001 by Informatics India Limited, this platform grants
                      users seamless entry to millions of online journal
                      articles sourced from 15,294 publishers. Presently, J-Gate
                      boasts an extensive database comprising literature from
                      67,487 periodical publications e-journals,
                      Conference-Proceedings, Book series) each with direct
                      links to full-text articles hosted on publisher websites.
                      Additionally, J-Gate aims to provide supplementary
                      services including online journal subscription, electronic
                      document delivery, archival facilities, and more. Key
                      features of J-Gate include:
                    </div>
                    <div>
                      <div
                        style={{
                          marginLeft: "30px",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "gray",
                          listStyle: "none",
                          marginBottom: "10px",
                        }}
                      >
                        <div>
                          1. Enhanced Content Coverage: Access a wider range of
                          content than ever before, ensuring you have all the
                          information you need at your fingertips.
                        </div>
                        <div>
                          2. Table of Contents (TOC) - Covering 67, 487
                          periodical publications.
                        </div>
                        <div>
                          3. Database - Offering a comprehensive searchable
                          database housing a staggering 83,565,438 articles,
                          with over 40,000 new articles incorporated daily.
                        </div>
                        <div>
                          4. New Search & Browse Experience: Our latest
                          technology provides a seamless and intuitive search
                          and browse experience, making it easier than ever to
                          find what you're looking for.
                        </div>
                        <div>
                          5. More Full text Coverage: Dive deeper into content
                          with expanded full-text coverage, allowing you to
                          explore topics comprehensively.
                        </div>
                        <div>
                          6. Mobile Applications (Android & iOS): Take your
                          experience on the go with our mobile applications,
                          available for both Android and iOS devices. Access
                          your favorite features anytime, anywhere.
                        </div>
                      </div>
                    </div>

                    <div className="subHeadingText">
                      Who is the intended audience for J-Gate?
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      Libraries, Students and faculties of Colleges and
                      universities, scientists, engineers, practitioners and
                      researchers of R&D, industries, corporate etc use J-Gate
                      for their educational and research needs and to know the
                      latest developments in their respective area of interest.
                    </div>
                    <div className="subHeadingText">
                      How often is the J-Gate database updated?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      J-Gate database is updated on a daily basis to ensure that
                      you have immediate access to the latest content.
                    </div>
                    <div className="subHeadingText">
                      How latest is the content on J-Gate when compared to
                      publisher's website?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      There is zero to two day lag in content when compared to
                      publisher's website.
                    </div>
                    <div className="subHeadingText">
                      What types of journals are included in J-Gate?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      J-Gate primarily indexes journals which are scientific and
                      scholarly, it also includes professional & industry
                      journals. Journals indexed in J-Gate should have full text
                      articles and there is no subject restriction for
                      inclusion. J-Gate covers journals published in English or
                      at least Table of Contents and abstracts are in English.
                      J-Gate indexes both open access and non open access
                      journals.
                    </div>
                    <div className="subHeadingText">
                      How does J-Gate mark a journal as Peer Reviewed?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      J-Gate flags a journal as peer reviewed if only the same
                      is indicated by the publishers on their websites
                    </div>
                    <div className="subHeadingText">
                      What are the types of Open Access Journals included in
                      J-Gate?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      Currently J-Gate marks only those journals as open access
                      which is free. It also includes those Open Access journals
                      which require online registration.
                    </div>
                    <div className="subHeadingText">
                      Are the journals subject classified using Library of
                      Congress or Dewey Decimal classification or any standards
                      subject headings?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      No, J-Gate uses a proprietary subject classification
                      system to classify journals.
                    </div>
                    <div className="subHeadingText">
                      Whom do I contact to get a journal included in J-Gate?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      To get your journal indexed in J-Gate, please send an
                      email with the name of the journal and its URL to
                      <a
                        href="mailto:jgatelicensing@informaticsglobal.ai"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          margin: "5px",
                          color: "#0d6efd",
                          cursor: "pointer",
                        }}
                      >
                        jgatelicensing@informaticsglobal.ai{" "}
                      </a>
                    </div>
                    <div className="subHeadingText">
                      Where do I get user instructions/help?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      <div style={{ marginLeft: "30px" }}>
                        <div>
                          i. Context sensitive online help: The help button is
                          located on the top navigation bar on all screens of
                          J-Gate .
                        </div>
                        <div>
                          ii. Online help facility: By clicking 'On Line
                          Support' button provided on the bottom navigation bar,
                          you will get help from one of our technical staff to
                          resolve your queries.
                        </div>
                        <div>
                          iii. If you require further technical assistance
                          please contact
                          <a
                            href="mailto:jcoordinator@informaticsglobal.ai"
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{
                              margin: "5px",
                              color: "#0d6efd",
                              cursor: "pointer",
                            }}
                          >
                            jcoordinator@informaticsglobal.ai{" "}
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="subHeadingText">
                      How can I request for Trial access?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "80px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      Please send an email to{" "}
                      <a
                        href="mailto:jgatelicensing@informaticsglobal.ai"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          margin: "5px",
                          color: "#0d6efd",
                          cursor: "pointer",
                        }}
                      >
                        jgatelicensing@informaticsglobal.ai{" "}
                      </a>
                      with your Name, Institution/Organization Name & address
                      along with your email id
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 700,
                        fontSize: "20px",
                        listStyle: "none",
                        color: "#0d6efd",
                      }}
                    >
                      J-Gate Coverage Statistics
                    </div>
                    <div className="subHeadingText">
                      How many journals are there in the portal?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      Presently J-Gate indexes 67,487 journals
                    </div>

                    <div className="subHeadingText">
                      How many are peer reviewed journals?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      Out of 67,487 journals indexed 66,195 are peer reviewed
                      journals
                    </div>
                    <div className="subHeadingText">
                      How many Open Access Journals are there in J-Gate?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      35,479 Open Access Journals are indexed in J-Gate
                    </div>
                    <div className="subHeadingText">
                      How many Open access Peer Reviewed Journals are in there
                      in J-Gate?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      34,689 Open access Peer Reviewed Journals are in there in
                      J-Gate
                    </div>
                    <div className="subHeadingText">
                      How many Indian journals?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      5,950 Indian journals are indexed in J-Gate
                    </div>
                    <div className="subHeadingText">
                      How many Indian peer reviewed journals?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      5,829 Indian peer reviewed journals are in J-Gate
                    </div>
                    <div className="subHeadingText">
                      How many publishers are indexed in J-Gate?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      15,299 publishers are indexed in J-Gate
                    </div>
                    <div className="subHeadingText">
                      How many articles are indexed in J-Gate?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      83,612,388 articles are covered in J-Gate
                    </div>
                    <div className="subHeadingText">
                      How many open access articles are indexed in J-Gate?
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      24,658,198 Open access articles are indexed in J-Gate
                    </div>
                    <div className="subHeadingText">
                      Does J-Gate indexes content other than journals?
                    </div>
                    <div
                      style={{
                        marginBottom: "80px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      Yes, J-Gate primarily indexes scholarly journals, but it
                      also includes other types of academic content such as
                      conference proceedings, dissertations, theses, A-V
                      lectures, Magazines and preprints. This comprehensive
                      coverage allows researchers to access a wide range of
                      academic literature across various disciplines.
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="3">
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 700,
                        fontSize: "20px",
                        listStyle: "none",
                        color: "#0d6efd",
                      }}
                    >
                      Features & Functionalities & Access related
                    </div>
                    <div className="subHeadingText">
                      What features are available in J-Gate?
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      The following features are available in J-Gate
                      <div style={{ marginLeft: "50px" }}>
                        <div>1. Basic search</div>
                        <div>2. Search Builder</div>
                        <div>3. Journal finder </div>
                        <div>4. Author finder </div>
                        <div>5. Alert services</div>
                        <div>6. Marked Results </div>
                        <div>7. Rights & access management system</div>
                        <div>8. Provision to filtering search results</div>
                      </div>
                    </div>

                    <div className="subHeadingText">
                      How to register for getting regular Alerts?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      Click on the 'Alerts' button provided against journal name
                      at the article level or at the journal in Journal finder
                      or user can click on the top navigation bar and register
                      by giving your name & email_id. After registration, please
                      select the journals for which you are interested in
                      getting alerts and save the profile. Upon saving your
                      profile, you will get regular alerts.
                      <br />
                      <br />
                      <div>
                        Use Search history to set the alert for most searched
                        topic pattern
                      </div>
                    </div>
                    <div className="subHeadingText">
                      Can I email articles using J-Gate?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      Yes, you can email the articles retrieved and selected
                      from J-Gate.
                    </div>
                    <div className="subHeadingText">
                      Can I download articles from the J-Gate site?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      Yes, you can download articles from J-Gate from all open
                      access journals and the journals subscribed by your
                      library.
                    </div>
                    <div className="subHeadingText">
                      How to search articles? What is the difference between
                      Basic Search & Search Builder
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      To search articles, J-Gate Currently provides both Basic
                      Search and Search Builder modes
                    </div>
                    <div>
                      <div
                        style={{
                          marginLeft: "20px",
                          marginBottom: "10px",
                          fontWeight: 500,
                          fontSize: "16px",
                          listStyle: "none",
                          color: "gray",
                        }}
                      >
                        <li>
                          i. Basic Search, meant for starters/novices, can be
                          used by typing in search box or by picking autosuggest
                          keywords. These keywords are searched in Title,
                          Abstract, and Keywords of the articles. You can do
                          Boolean search or exact phrase search and also limit
                          your search results for the following parameters
                          <div
                            style={{
                              marginLeft: "50px",
                              marginBottom: "10px",
                              fontWeight: 500,
                              fontSize: "16px",
                              listStyle: "none",
                              color: "gray",
                            }}
                          >
                            <div>1. Subjects</div>
                            <div>2. Document types</div>
                            <div>3. Collections</div>
                            <div>4. Publication Dates</div>
                          </div>
                        </li>
                        <li>
                          ii. In Search Builder, meant for by experienced users.
                          You can do lot of combination searches and restrict
                          your search to any of the parameters. You can use the
                          query builder editing box to modify the preferences
                          for the given search terms
                          <div
                            style={{
                              marginLeft: "50px",
                              marginBottom: "10px",
                              fontWeight: 500,
                              fontSize: "16px",
                              listStyle: "none",
                              color: "gray",
                            }}
                          >
                            <div>
                              1. Pick and choose the field(s) provided in the
                              dropdown menu like Article Title, Author, Author
                              affiliation, Title/Keywords/Abstract,
                              Title/Keywords etc.
                            </div>
                            <div>2. Perform Boolean searches</div>
                            <div>3. Perform exact phrase search</div>
                            <div>4. Nested logic search</div>
                            <div>
                              5. You can limit your search results within a wide
                              range like
                            </div>
                            <div
                              style={{
                                marginLeft: "65px",
                                marginBottom: "10px",
                                fontWeight: 500,
                                fontSize: "16px",
                                listStyle: "none",
                                color: "gray",
                              }}
                            >
                              <div>i. Subjects</div>
                              <div>ii. Document Types</div>
                              <div>iii. Publication year</div>
                              <div>iv. Pick from journal list</div>
                            </div>
                          </div>
                        </li>
                      </div>
                    </div>
                    <div className="subHeadingText">
                      How to find author of my choice?
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      <div
                        style={{
                          marginLeft: "20px",
                          marginBottom: "10px",
                          fontWeight: 500,
                          fontSize: "16px",
                          listStyle: "none",
                          color: "gray",
                        }}
                      >
                        <div>
                          1. Look for the "Author Finder" from Menu bar.
                        </div>
                        <div>
                          2. Once you're in the Author Finder tool, you can
                          typically search for authors by their first and last
                          name.
                        </div>
                        <div>
                          3. Enter the name of the author you're looking for
                          into the search bar and click on the search button.
                        </div>
                        <div>
                          4. Review the search results to find the specific
                          author you're interested in. select the required
                          authors from the listing and click on View Results at
                          the top.
                        </div>
                      </div>
                    </div>
                    <div className="subHeadingText">
                      How to access full text articles which are not subscribed
                      by my institution but available in the portal?{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      To access the full text articles which are not subscribed
                      by your institution, you can either send an email to the
                      author using the author email id provided or you can write
                      to one of the institutions listed against the article
                      under 'Find-in-a-Library'.
                    </div>
                    <div className="subHeadingText">
                      Can I access the table of contents for all the journals
                      subscribed by my library{" "}
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      Yes, you can access the table of contents for all the
                      journals subscribed by your library through J-Gate.
                    </div>
                    <div className="subHeadingText">
                      I have access to J-Gate portal, but not able to access
                      fulltext at publisher site
                    </div>
                    <div
                      style={{
                        marginBottom: "80px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                      }}
                    >
                      Please contact{" "}
                      <a
                        href="mailto:jgatelicensing@informaticsglobal.ai"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          margin: "5px",
                          color: "#7852fb",
                          cursor: "pointer",
                        }}
                      >
                        jgatelicensing@informaticsglobal.ai{" "}
                      </a>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="4">
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 700,
                        fontSize: "20px",
                        listStyle: "none",
                        color: "#0d6efd",
                      }}
                    >
                      Subscription
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      Subscribing to J-Gate offers institutions a cost-effective
                      way to access a wealth of scholarly content, enhance
                      research capabilities, and support the academic and
                      professional endeavors of their users
                      <div style={{ marginLeft: "30px" }}>
                        <div>
                          1. Comprehensive Access: Subscribing to J-Gate
                          provides access to millions of journal articles from a
                          wide range of publishers, covering various subject
                          areas and disciplines.
                        </div>
                        <div>
                          2. Flexible Options: J-Gate offers flexible
                          subscription options tailored to the needs of
                          different institutions, including academic libraries,
                          research organizations, and corporate entities.
                        </div>
                        <div>
                          3. Value for Money: Subscribing to J-Gate offers
                          excellent value for money by providing access to a
                          vast collection of high-quality e-journal literature
                          at a reasonable cost.
                        </div>
                        <div>
                          4. Customization: Institutions can customize their
                          subscriptions to J-Gate based on their specific
                          requirements, including the number of users, access
                          levels, and content preferences.
                        </div>
                        <div>
                          5. Access to Latest Research: Subscribers benefit from
                          regular updates and additions to the J-Gate database,
                          ensuring access to the latest research and scholarly
                          literature.
                        </div>
                        <div>
                          6. User Support: J-Gate provides dedicated customer
                          support to assist subscribers with any queries or
                          technical issues they may encounter, ensuring a smooth
                          and hassle-free experience.
                        </div>
                        <div>
                          7. Usage Statistics: Subscribers have access to usage
                          statistics and analytics, allowing them to track usage
                          patterns, measure the impact of their subscriptions,
                          and make informed decisions about content acquisition.
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="5">
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 700,
                        fontSize: "20px",
                        listStyle: "none",
                        color: "#0d6efd",
                      }}
                    >
                      Technical Questions
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      <div>
                        J-Gate addresses technical queries proactively and
                        provide users with the necessary support and resources
                        to ensure a positive user experience with J-Gate
                      </div>
                      <div
                        style={{
                          marginBottom: "10px",
                          fontWeight: 500,
                          fontSize: "16px",
                          listStyle: "none",
                          color: "gray",
                          marginLeft: "30px",
                        }}
                      >
                        <div>
                          1. Dedicated Technical Support: J-Gate provides
                          dedicated technical support to assist with any
                          technical queries or issues user may encounter.
                          Encourage them to reach out to the technical support
                          team for assistance.
                        </div>
                        <div>
                          2. Online Help Resources: Direct users to online help
                          resources, such as user guides, FAQs, which may help
                          user to resolve common technical issues on their own.
                        </div>
                        <div>
                          3. Contact Information: Provide users with contact
                          information for the J-Gate technical support team,
                          including email addresses and phone numbers, so they
                          can easily reach out for assistance.
                        </div>
                        <div>
                          4. Response Time: Inform users about the typical
                          response time for technical support inquiries and
                          assure them that their concerns will be addressed
                          promptly.
                        </div>
                        <div>
                          5. Problem Reporting: Encourage users to report any
                          technical issues they encounter with J-Gate, including
                          error messages, system glitches, or usability issues.
                          Assure them that their feedback is valuable for
                          improving the platform.
                        </div>
                        <div>
                          6. Updates and Maintenance: Inform users about
                          scheduled updates, maintenance, or downtime periods
                          for J-Gate, so they are aware of any potential
                          disruptions to service.
                        </div>
                        <div>
                          7. User Training: Offer user training sessions or
                          workshops to help users familiarize themselves with
                          the technical aspects of using J-Gate effectively,
                          such as search techniques, advanced features, and
                          customization options.
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="6">
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 700,
                        fontSize: "20px",
                        listStyle: "none",
                        color: "#0d6efd",
                      }}
                    >
                      For Publishers
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      Highlight on the benefits and value proposition of
                      partnering with J-Gate platform:
                      <div
                        style={{
                          marginBottom: "10px",
                          fontWeight: 500,
                          fontSize: "16px",
                          color: "gray",
                          listStyle: "none",
                          marginLeft: "30px",
                        }}
                      >
                        <div>
                          1. Increased Visibility: J-Gate offers publishers an
                          opportunity to increase the visibility of their
                          journals and articles by making them accessible to a
                          wide audience of researchers, academics, and
                          institutions worldwide.
                        </div>
                        <div>
                          2. Global Reach: Partnering with J-Gate provides
                          publishers with access to a global audience, allowing
                          them to reach researchers and readers in regions they
                          may not have previously accessed.
                        </div>
                        <div>
                          3. Enhanced Discoverability: J-Gate's advanced search
                          functionalities and indexing capabilities ensure that
                          publishers' content is easily discoverable by users
                          searching for specific topics, authors, or keywords.
                        </div>
                        <div>
                          4. Access Options: J-Gate offers both open-access and
                          subscription-based models, allowing publishers to
                          choose the access model that best suits their needs
                          and business goals.
                        </div>
                        <div>
                          5. Support Services: J-Gate provides publishers with
                          dedicated support services to assist with content
                          ingestion, metadata management, and other technical
                          aspects of content delivery.
                        </div>
                        <div>
                          6. Collaborative Partnerships: J-Gate values
                          collaborative partnerships with publishers, working
                          closely with them to ensure that their content is
                          presented effectively and meets the needs of users.
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="7">
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 700,
                        fontSize: "20px",
                        listStyle: "none",
                        color: "#0d6efd",
                      }}
                    >
                      For Librarians
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        color: "gray",
                        listStyle: "none",
                      }}
                    >
                      J-Gate acknowledges its significance as a comprehensive
                      electronic gateway to global e-journal literature. Here
                      are some key points which support your patrons' research
                      needs effectively :
                    </div>
                    <div
                      style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",
                        listStyle: "none",
                        color: "gray",
                        marginLeft: "30px",
                      }}
                    >
                      <li>
                        1. Extensive Coverage: J-Gate provides access to
                        millions of journal articles available online from
                        various publishers, covering a wide range of subject
                        areas.
                      </li>
                      <li>
                        2. Seamless Access: Users can easily access and search
                        for specific articles, authors, or topics of interest
                        using J-Gate's advanced search functionalities.
                      </li>
                      <li>
                        3. Full-Text Access: J-Gate offers access to full-text
                        articles from both open-access and subscription-based
                        journals / articles, ensuring users have access to the
                        content they need.
                      </li>
                      <li>
                        4. Content Updates: The platform is regularly updated to
                        provide users with the latest research and literature,
                        with new content added on a daily basis.
                      </li>
                      <li>
                        5. Customization Options: J-Gate allows users to save
                        searches, set up alerts for new content, and customize
                        their preferences, providing a personalized experience.
                      </li>
                      <li>
                        6. Support and Assistance: J-Gate offers customer
                        support through various channels, including email,
                        phone, and online help resources, to assist users with
                        any queries or issues they may encounter.
                      </li>
                      <li>
                        7. Feedback Mechanism: J-Gate welcomes feedback and
                        suggestions from users to continuously improve the
                        platform, ensuring it meets the needs of librarians and
                        their patrons.{" "}
                         <a
                        href="https://forms.office.com/Pages/ResponsePage.aspx?id=M_4_nylwpkuTMKIkglk7dh9jpA7zC89LgjVE64YxnDtUOEFNTEM4WkZTNVJSQVI1SktIQlFXNDg5RS4u"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{
                          margin: "5px",
                          color: "#0d6efd",
                          cursor: "pointer",
                        }}
                      >
                        Feedback Link
                      </a>
                      </li>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="8">
                    <div style={{
                        marginBottom: "10px",
                        fontWeight: 700,
                        fontSize: "20px",                       
                        listStyle: "none",
                        color: "#0d6efd",
                      }}>Frequently Asked Questions</div>
                    <div className="subHeadingText">
                  What is J-Gate?
                </div>
                <div style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  J-Gate is an electronic gateway to global e-journal
                  literature, providing seamless access to millions of articles
                  available online from various publishers.
                </div>
                <div className="subHeadingText">
                  How can I access J-Gate?
                </div>
                <div style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  You can access J-Gate through our website by creating an
                  account or accessing it through subscribing institutions.
                </div>
                <div className="subHeadingText">
                  What content does J-Gate cover?
                </div>
                <div style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  J-Gate covers a vast range of scholarly content from
                  e-journals, conference papers, Book series, Theses,
                  Pre-Prints, Audio-Visual lectures including articles, research
                  papers, reviews, and more, across various subject areas.
                </div>
                <div className="subHeadingText">
                  Can I search for specific articles or topics on J-Gate?
                </div>
                <div style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  Yes, J-Gate offers advanced search functionalities that allow
                  users to search for specific articles, authors, keywords, or
                  topics of interest.
                </div>
                <div className="subHeadingText">
                  Does J-Gate provide access to full-text articles?
                </div>
                <div style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  Yes, J-Gate provides access to full-text articles from both
                  open-access and subscription-based journals, depending on the
                  availability and access rights. J-Gate also provides to
                  alternative full-text versions from preprint sources for
                  articles behind paywalls
                </div>
                <div className="subHeadingText">
                  Is there a way to save my searches or set up alerts for new
                  content?
                </div>
                <div style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  Yes, J-Gate offers features to save searches and set up alerts
                  for new content based on specified search criteria, ensuring
                  you stay updated with the latest research.
                </div>
                <div className="subHeadingText">
                  How frequently is the content on J-Gate updated?
                </div>
                <div style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  The content on J-Gate is regularly updated to ensure users
                  have access to the most current research and literature.
                  Updates typically occur daily.
                </div>
                <div className="subHeadingText">
                  Can I access J-Gate on mobile devices?
                </div>
                <div style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  Yes, J-Gate is accessible on various mobile devices, allowing
                  users to search and access content on-the-go.
                </div>
                <div className="subHeadingText">
                  What support options are available if I encounter any issues?
                </div>
                <div style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  J-Gate provides customer support through various channels,
                  including email, phone, and online help resources, to assist
                  users with any queries or issues they may encounter.
                </div>
                <div className="subHeadingText">
                  Is there a way to provide feedback or suggestions for
                  improvement?
                </div>
                <div style={{
                        marginBottom: "10px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  Yes, J-Gate welcomes feedback and suggestions from users to
                  continuously improve the platform. Users can submit feedback
                  through the website or contact customer support directly.
                </div>
                
                <div style={{
                        marginBottom: "80px",
                        fontWeight: 500,
                        fontSize: "16px",                       
                        listStyle: "none",
                        color: "gray",
                      }}>
                  These FAQs aim to address common queries and provide
                  assistance to users navigating the J-Gate platform. If you
                  have any further questions or require additional support,
                  please don't hesitate to contact our help desk.
                </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </Container>
    </div>
  );
};

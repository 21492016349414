import React, { useEffect, useState, Fragment } from "react";
import { Button } from "react-bootstrap";
import { ContuLendingInfoDialog } from "../../atoms/Modals/ContuLendingInfoDialog";
import Radio from "../../atoms/Radio/Radio";
import { exportContuLendingReport } from "../../containers/Reports/constants";
import ContuLendingArticleInfoTable from "./ContuLendindArticleInfo";
import _ from "lodash";
import { Pagination } from "../ReportsComponents/Pagination";
import Loading from "../Loading/Loading";

const ContuBorrowedILLDeliveredDataTable = ({
  isIndexed,
  iLLReferenceData,
  isTableLoad,
}) => {
  const [isContLendingArtcleData, setIsContLendingArtcleData] = useState(false);
  const [downloadType, setDownloadType] = useState({
    export_type: "excel",
  });
  const [refrenceId, setReferenceId] = useState("");
  const [selectedOption, setSelectedOption] = useState("excel");

  iLLReferenceData &&
    iLLReferenceData?.forEach((item, ind) => {
      item.id = ind + 1;
    });

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    iLLReferenceData &&
    iLLReferenceData?.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(
    iLLReferenceData && iLLReferenceData?.length / recordsPerPage
  );

  useEffect(() => {
    iLLReferenceData &&
      iLLReferenceData?.map((val) => setReferenceId(val.reference_id));
  }, [iLLReferenceData]);

  const handleButton = () => {
    setIsContLendingArtcleData(true);
  };

  const handleContuExport = async () => {
    const _dat = _.cloneDeep(downloadType);
    const ddrIds = iLLReferenceData && iLLReferenceData[0].map((val)=>val.reference_id)
    _dat["export_type"] = selectedOption;
    // _dat["ddr_reference_id"] = refrenceId && refrenceId;
    _dat["ddr_reference_id"] = ddrIds;
    _dat["report_type"] = "1";

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");
    window.open(exportContuLendingReport(str));
  };

  const handleRadioButton = (value, name) => {
    const _dat = _.cloneDeep(downloadType);
    _dat[name] = value;
    setDownloadType(_dat);
  };

  const handleRadioChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <Fragment>
      {/* <ContuLendingInfoDialog
        show={isContLendingArtcleData}
        modalClose={setIsContLendingArtcleData}
        content={
          <Fragment>
            <div>
              <ContuLendingArticleInfoTable
                iLLReferenceData={currentRecords && currentRecords}
                isIndexed="true"
              />
            </div>
            <div>
              <Pagination
                nPages={nPages && nPages}
                currentPage={currentPage && currentPage}
                setCurrentPage={setCurrentPage}
                setRecordsPerPage={setRecordsPerPage}
                tableDataReport={iLLReferenceData && iLLReferenceData}
                recordsPerPage={recordsPerPage && recordsPerPage}
              />
            </div>
          </Fragment>
        }
        successCallback={handleButton}
        heading="CONTU Details"
        dialogClassName={"ContuTableModal"}
      /> */}
      <div>
        <div className="CountuLendingReportRowExport">
          <input
            type="radio"
            name="options"
            value="excel"
            checked={selectedOption === "excel"}
            onChange={handleRadioChange}
            className="radiobuttonalin"
          />
          <span className="contuLendingRadioBtn"> Excel</span>
          <input
            type="radio"
            name="options"
            value="csv"
            checked={selectedOption === "csv"}
            onChange={handleRadioChange}
            className="radiobuttonalin"
          />
          <span className="contuLendingRadioBtn"> CSV</span>
          {/* <Radio
            label="Excel"
            name="export_type"
            value={"excel"}
            checkedValue={downloadType["export_type"]}
            handleInputchange={handleRadioButton}
          />
          <Radio
            label="CSV"
            name="export_type"
            value={"csv"}
            checkedValue={downloadType["export_type"]}
            handleInputchange={handleRadioButton}
          /> */}

          <Button
            onClick={handleContuExport}
            className="contactPersondata radiobuttonalin"
          >
            Export
          </Button>
        </div>
        {isTableLoad && <Loading />}
        <table className="table table-bordered table-striped">
          <thead className="summayTable">
            <tr>
              <th className="contuSno">SI.No</th>
              <th>Requested To</th>
              <th>Article Info</th>
              <th>DDR ID</th>
              <th className="contuDate">Request Date </th>
            </tr>
          </thead>
          <tbody>
            {!iLLReferenceData && (
              <tr className="text-center contactPersondata">
                <td colSpan="4">
                  <strong>No Data Available</strong>
                </td>
              </tr>
            )}
            {iLLReferenceData &&
              iLLReferenceData[0]?.map((data, index) => (
                <tr key={index} className="contactPersondata">
                  {isIndexed && <td className="ddrtable"> {index + 1} </td>}
                  <td>{data.request_to}</td>
                  {/* <td className="ddrtable ">
                    <span
                      className="coutuTable"
                      onClick={() => handleButton(data)}
                    >
                      {data.article_name}
                    </span>
                  </td> */}
                  <td className="ddrtable ">{data.article_name}</td>
                  <td className="ddrtable ">{data.reference_id}</td>
                  <td className="ddrtable me-4">{data.request_date} </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </Fragment>
  );
};

export default ContuBorrowedILLDeliveredDataTable;

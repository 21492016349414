import React, { Fragment } from "react";


const ContuLendingArticleInfoTable = ({ iLLReferenceData }) => {


  return (
    <Fragment>
      <table className="table table-bordered table-striped">
        <thead className="summayTable">
          <tr>
            <th className="contuSno">SI.No</th>
            <th>Article Title</th>
            <th>Issue</th>
            <th>volume </th>
            <th>Publication Date</th>
            <th>Journal Name</th>
            <th>Requester Name </th>
            <th>Requester Email </th>
          </tr>
        </thead>
        <tbody>
          {!iLLReferenceData && (
            <tr className="text-center contactPersondata">
              <td colSpan="4">
                <strong>No Data Available</strong>
              </td>
            </tr>
          )}
          {iLLReferenceData &&
            iLLReferenceData[0]?.map((data, index) => (
              <tr key={index} className="contactPersondata">
                {/* {isIndexed && <td className="ddrtable"> {index + 1} </td>} */}
                <td className="ddrtable"> {data.id} </td>
                <td>{data.article_name ? data.article_name : "-"}</td>
                <td className="ddrtable ">{data.issue ? data.issue : "-"}</td>
                <td className="ddrtable me-4">
                  {data.volume ? data.volume : "-"}{" "}
                </td>
                <td className="ddrtable me-4">
                  {data.publish_date ? data.publish_date : "-"}{" "}
                </td>
                <td className="ddrtable me-4">
                  {data.resource_name ? data.resource_name : "-"}{" "}
                </td>
                <td className="ddrtable me-4">
                  {data.requester_user_name ? data.requester_user_name : "-"}{" "}
                </td>
                <td className="ddrtable me-4">
                  {data.user_email ? data.user_email : "-"}{" "}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default ContuLendingArticleInfoTable;

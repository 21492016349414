import React, { useState } from "react";
// import { NavLink } from "react-router-dom";
// import LogoInformatics from "../Header/LogoInformatics";
import LogoInformaticsImg from "../../assets/img/Logo/informatics-logo.png";
import "./Footer.css";
import { NewModal } from "../Modals/Modal";
import { BrowserCompatibility } from "./BrowserCompatibity";

/**
 * @author VimalKovath
 * @function Footer
 **/

const Footer = (props) => {
  const [isBrowserCompatibilityModel, setIsBrowserCompatibilityModel] = useState(false)
  const browserCompatibilityData =(
    <BrowserCompatibility/>
  )

  const handleBrowserCompatibility =()=>{
    setIsBrowserCompatibilityModel(true)
  }
  
  return (
    <footer className={`main-footer ${props.isSidebar} footer`}>
      <NewModal
          show={isBrowserCompatibilityModel}
          modalHandle={setIsBrowserCompatibilityModel}
          data={browserCompatibilityData}
          size="md"
          heading="Browser Compatibility"
          //dialogClassName={"OrgViewTableModal"}
        />
      <div className="container ">
        <div className="footerContents ">
        <div>
            <img src={LogoInformaticsImg} width="143" height="33" alt="" />
          </div>
          <div className="Links">
            <span className="acount-icon">
              <a href="/aboutus" target="_blank">
                <span className="footerfont"> About</span>
              </a>
              <a href="/faq" target="_blank">
                <span className="footerfont"> FAQ</span>
              </a>
              <a href="/contactus" target="_blank">
                <span className="footerfont"> Contact Us</span>
              </a>

            </span>
            <span style={{fontWeight:"640", color:"black", cursor:"pointer"}} onClick={handleBrowserCompatibility}>Browser compatibility</span>
          </div>
          <div className="copyright">
            Copyright © 2024 . Informatics India Ltd. All Rights Reserved.
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";
import "./Footer.css";

// contactus screen
export const Contactus = () => {
  return (
    <>
      <div className="contactheadermargin main">
        <div className="container ft_border ">
          <h4 className="ft_contact ms-5 mt-5">Contact Us</h4>
          <div className="row">
            <div className="col-lg-5">
              <p className="ms-5">
                <strong>Head Office</strong>
              </p>
              <p className="ms-5">
                <strong>Informatics India Ltd</strong>
                <br />
                No 194, R V Road, P B No 400 <br />
                Basavanagudi <br />
                <br /> Bangalore - 560 004 &nbsp; Phone: +91 80 40387777 <br />
                Fax: +91 80 40387600 <br /> e-mail: info@informaticsglobal.ai
                <br />
                <br />
                <br />
              </p>
            </div>
            <div className="col-lg-5">
              <p className="ms-5">
                <strong> Product Support</strong>
              </p>
              <p className="ms-5">
                Phone: +91 80 40387777
                <br />
                Fax: +91 80 40387600
                <br />
                e-mail: jcoordinator@informaticsglobal.ai
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import _ from "lodash";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import Radio from "../../atoms/Radio/Radio";
import InputField from "../../atoms/InputField/InputField";
import {
  exportSentDDRMngListUrl,
  formatedObject,
  formatedSelect,
} from "../../containers/Reports/constants";
import {
  getConsortiaMemberList,
  radioDataValues,
  getSentDDRListPagewise,
  getDDRLinkResolverLink,
} from "../../containers/DDR/constants";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import Loading from "../../components/Loading/Loading";
import { Button } from "react-bootstrap";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import BasicSelectDropDown from "../../atoms/DropDown/BasicSelectDropDown";
import DDRSentArticleTable from "./DDRSentArticleTable";
import ScrollBottomArrow from "./ScrollBottomArrow";
import ScrollTopArrow from "./ScrollTopArrow";
import { DDRPagination } from "./DDRPagination";
import DDRLoading from "../Loading/DDRLoading";
import { InfoDialogconsortia } from "../../atoms/Modals/InfoDialogconsortis";
import { calculateToDate, handleStatusChange } from "../../utilities/formatedSelect";

const ConsortiaDDRSend = ({ customerID, consortiamaster_id, priority }) => {
  const [tableDataDDRR, setTableDataDDRR] = useState([]);
  const [loading, setLoading] = useState(false);
  const [fromDatedata, setFromDatedata] = useState("");
  const [isFromdateLoad, setIsFromdateLoad] = useState(false);
  const [isFromdate, setIsFromdate] = useState(false);
  const [numOfPages, setNumOfPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const [numOfRecords, setNumOfRecords] = useState();
  const [currentStatus, setCurrentStatus] =useState() 
  const member_id = "2";
  const [linkResolverData, setLinkResolverData] = useState([]);
  let currentyear = new Date().getFullYear();
  let fromDateFormate = `${currentyear}-01-01`;
  const [isInfo, setIsInfo] = useState(false);

  const [data, setData] = useState({
    request_to_id: "0",
    ddr_status: "1",
    ddr_from_date: fromDateFormate.toString(),
    ddr_to_date: formatDate(),
    export_type: "excel",
    ddr_priority: "0",
    ddr_id: "0",
    customerId: customerID,
  });
  useEffect(() => {
    setData({
      request_to_id: data && data?.request_to_id,
      ddr_status: data && data?.ddr_status,
      //ddr_from_date: fromDatedata,
      ddr_from_date: fromDateFormate.toString(),
      ddr_to_date: formatDate(),
      export_type: data && data?.export_type,
      ddr_priority: data && data?.ddr_priority,
      ddr_id: data && data?.ddr_id,
      customerId: data & data?.customerId,
    });
  }, [fromDatedata, isFromdateLoad]);

  useEffect(() => {
    handleLoadButton();
  }, [currentPage, recordsPerPage]);

  useEffect(() => {
    goToTop();
  }, [recordsPerPage, currentPage]);

  function formatDate() {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const consortiaMemberList = useFetch(
    getConsortiaMemberList(consortiamaster_id),
    {}
  );

  const handleInputchange = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    setData(_dat);
  };

  const handleInputchangeInstitute = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    if (_dat["request_to_id"] == "") {
      _dat["request_to_id"] = "0";
    }
    setData(_dat);
  };

  const handleInputchangeCustomerId = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;

    if (_dat["customerId"] == "") {
      _dat["customerId"] = customerID;
    }
    setData(_dat);
  };

  const linkToDisplay = (dataArray) => {
    if (!Array.isArray(dataArray)) return;
    let formData = new FormData();

    let data = dataArray?.map((obj) => {
      let object = {
        customer_id: obj?.ddr_req_to,
        article_id: obj?.article_id,
        // consortia_virtualid: customerData?.consortia_virtualid,
        resource_type: obj?.resource_type_id,
        access_type: obj?.access_type_id,
        is_oa_article: obj?.is_oa_article,
        article_doi: obj?.article_doi,
        resourceissue_id: obj?.issue_id,
      };
      return object;
    });

    let formApiData = {
      data,
    };

    formData.append("detailed_json", JSON.stringify(formApiData));
    fetch(getDDRLinkResolverLink, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (result?.message === "Ok") {
          setLinkResolverData(result);
        } else {
          setLinkResolverData("");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };


  // const handleStatusChange = (_dat) => {
  //   switch (_dat["ddr_status"]) {
  //     case "":
  //     case "0":
  //       setCurrentStatus("All");
  //       break;
  //     case "1":
  //       setCurrentStatus("Pending");
  //       break;
  //     case "2":
  //       setCurrentStatus("Fulfilled Request");
  //       break;
  //     case "3":
  //       setCurrentStatus("Delayed");
  //       break;
  //     case "4":
  //       setCurrentStatus("Article not Available");
  //       break;
  //     case "5":
  //       setCurrentStatus("Discarded");
  //       break;
  //     case "6":
  //       setCurrentStatus("Delivered outside the DDR system");
  //       break;
  //     case "7":
  //       setCurrentStatus("Duplicate Request");
  //       break;
  //     default:
  //       setCurrentStatus("Pending");
  //   }
  // };

  const handleLoad = async () => {
    setLoading(true);
    setCurrentPage(1);
    const _dat = _.cloneDeep(data);
    // _dat["customerId"] = customerID;
    _dat["consortia_id"] = consortiamaster_id;
    _dat["rpp"] = recordsPerPage;
    _dat["page_no"] = currentPage;
    delete _dat.export_type;

    if (_dat["ddr_id"] === "") {
      _dat["ddr_id"] = "0";
    }

    if (_dat["ddr_priority"] === "") {
      _dat["ddr_priority"] = "0";
    }

    if (_dat["request_to_id"] === "") {
      _dat["request_to_id"] = "0";
    }

    if (_dat["customerId"] == "") {
      _dat["customerId"] = customerID;
    }
    // if (_dat["customerId"] === "0") {
    //   setIsInfo(true)
    // }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    // const response = await fetchUrl(getSentDDRList(str));
    const response = await fetchUrl(getSentDDRListPagewise(str));
    if (response?.message === "Ok") {
      linkToDisplay(response?.data[0][0]?.DDR_Sent_List);
    }

    if (
      !!response &&
      !!response.data &&
      response.data[0] &&
      response.data[0][0] &&
      response.data[0][0].DDR_Sent_List
    ) {
      setTableDataDDRR(response.data[0][0].DDR_Sent_List);
      setNumOfPages(response.data[0][0].pagination);
      setNumOfRecords(response.data[0][0].no_of_records);
      handleStatusChange(_dat,setCurrentStatus)
      setLoading(false);
    }
    if (
      response &&
      response.data &&
      response.data[0][0].DDR_Sent_List === null
    ) {
      setTableDataDDRR("");
      setNumOfRecords("");
      setLoading(false);
    }
  };

  const handleLoadButton = async () => {
    setLoading(true);
    const _dat = _.cloneDeep(data);
    // _dat["customerId"] = customerID;
    _dat["consortia_id"] = consortiamaster_id;
    _dat["rpp"] = recordsPerPage;
    _dat["page_no"] = currentPage;

    delete _dat.export_type;
    if (_dat["ddr_id"] === "") {
      _dat["ddr_id"] = "0";
    }

    if (_dat["ddr_priority"] === "") {
      _dat["ddr_priority"] = "0";
    }

    if (_dat["request_to_id"] === "") {
      _dat["request_to_id"] = "0";
    }

    if (_dat["customerId"] == "") {
      _dat["customerId"] = customerID;
    }

    // if (_dat["customerId"] === "0") {
    //   setIsInfo(true)
    // }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    // const response = await fetchUrl(getSentDDRList(str));
    const response = await fetchUrl(getSentDDRListPagewise(str));

    if (response?.message === "Ok") {
      linkToDisplay(response?.data[0][0]?.DDR_Sent_List);
    }

    if (
      !!response &&
      !!response.data &&
      response.data[0] &&
      response.data[0][0] &&
      response.data[0][0].DDR_Sent_List
    ) {
      setTableDataDDRR(response.data[0][0].DDR_Sent_List);
      setNumOfPages(response.data[0][0].pagination);
      setNumOfRecords(response.data[0][0].no_of_records);
      handleStatusChange(_dat,setCurrentStatus)
      setLoading(false);
    }
    if (
      response &&
      response.data &&
      response.data[0][0].DDR_Sent_List === null
    ) {
      setTableDataDDRR("");
      setNumOfRecords("");
      setLoading(false);
    }
  };

  const handleSearchIcon = async () => {
    setLoading(true);
    setCurrentPage(1);
    const _dat = _.cloneDeep(data);
    // _dat["customerId"] = customerID;
    _dat["consortia_id"] = consortiamaster_id;
    _dat["rpp"] = recordsPerPage;
    _dat["page_no"] = currentPage;

    delete _dat.export_type;
    if (_dat["ddr_id"] === "") {
      _dat["ddr_id"] = "0";
    }

    if (_dat["ddr_priority"] === "") {
      _dat["ddr_priority"] = "0";
    }

    if (_dat["request_to_id"] === "") {
      _dat["request_to_id"] = "0";
    }

    if (_dat["customerId"] == "") {
      _dat["customerId"] = customerID;
    }

    // if (_dat["customerId"] === "0") {
    //   setIsInfo(true)
    // }
    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    // const response = await fetchUrl(getSentDDRList(str));
    const response = await fetchUrl(getSentDDRListPagewise(str));

    if (response?.message === "Ok") {
      linkToDisplay(response?.data[0][0]?.DDR_Sent_List);
    }
    if (
      !!response &&
      !!response.data &&
      response.data[0] &&
      response.data[0][0] &&
      response.data[0][0].DDR_Sent_List
    ) {
      setTableDataDDRR(response.data[0][0].DDR_Sent_List);
      setNumOfPages(response.data[0][0].pagination);
      setNumOfRecords(response.data[0][0].no_of_records);
      handleStatusChange(_dat,setCurrentStatus)
      setLoading(false);
    }
    if (
      response &&
      response.data &&
      response.data[0][0].DDR_Sent_List === null
    ) {
      setTableDataDDRR("");
      setNumOfRecords("");
      setLoading(false);
    }
  };

  const handleExport = async () => {
    const _dat = _.cloneDeep(data);
    // _dat["customerId"] = customerID;
    _dat["consortia_id"] = consortiamaster_id;

    if (_dat["customerId"] == "") {
      _dat["customerId"] = customerID;
    }

    if (_dat["request_to_id"] == "") {
      _dat["request_to_id"] = "0";
    }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : "="}${val}`)
      .join("&");

    window.open(exportSentDDRMngListUrl(str));
  };

  const handleDisableExport = () => {
    return tableDataDDRR && tableDataDDRR.length > 0;
  };

  const handleFromDateChange = (value) => {
    const _dat = _.cloneDeep(data);
    _dat["ddr_from_date"] = value;

    const fromDate = new Date(value);
    const toDate = new Date(fromDate);
    toDate.setDate(fromDate.getDate() + 365);

    const today = new Date();
    if (toDate > today) {
      _dat["ddr_to_date"] = formatDate(today);
    } else {
      _dat["ddr_to_date"] = toDate.toISOString().split("T")[0];
    }

    setData(_dat);
  };

  return (
    <div className="DDRRecived">
      <CommonHeading heading="DDR Sent" />
      <ScrollBottomArrow />

      <InfoDialogconsortia
        show={isInfo}
        modalClose={setIsInfo}
        content={"Please select member to view details"}
        //successCallback={handleLoadButton}
        heading="Info"
      />
      <div className=" DDRRExportRow">
        <Radio
          label={"Excel"}
          value={"excel"}
          name="export_type"
          checkedValue={data["export_type"]}
          handleInputchange={handleInputchange}
        />
        <Radio
          label={"CSV"}
          value={"csv"}
          name="export_type"
          checkedValue={data["export_type"]}
          handleInputchange={handleInputchange}
        />

        <Button
          className="contactPersondata"
          onClick={handleExport}
          disabled={!handleDisableExport()}
        >
          Export
        </Button>
      </div>
      <div className="DDRRecivedcontrolRows">
        <div className="DDRRecivedRadioRow">
          {Object.keys(radioDataValues).map((key, index) => (
            <Radio
              label={key}
              value={radioDataValues[key]}
              name="ddr_status"
              checkedValue={data["ddr_status"]}
              handleInputchange={handleInputchange}
              key={index}
            />
          ))}
        </div>
      </div>

      <div className="DDRRecivedInstutionRow mt-3">
        <div className="row">
          <div className="col-md-12 d-flex">
            <div className="common-select-space w-400 inline">
              {consortiaMemberList.isLoading && <Loading />}
              {!consortiaMemberList.isLoading &&
                consortiaMemberList.response &&
                !!consortiaMemberList.response.data && (
                  <BasicSelectDropDown
                    data={formatedObject(consortiaMemberList.response.data)}
                    name="customerId"
                    label="Request Sent By"
                    handleInputchange={handleInputchangeCustomerId}
                  />
                )}
            </div>
            <div className="common-select-space w-400 inline">
              {consortiaMemberList.isLoading && <Loading />}
              {!consortiaMemberList.isLoading &&
                consortiaMemberList.response &&
                !!consortiaMemberList.response.data && (
                  <BasicSelectDropDown
                    data={formatedObject(consortiaMemberList.response.data)}
                    name="request_to_id"
                    label="Request Received By"
                    handleInputchange={handleInputchangeInstitute}
                  />
                )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <span style={{ color: "red" }}>
              <strong>
                The selected date must be within 12-months or 1-year range.
              </strong>
            </span>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 d-flex">
            <div>
              <InputField
                placeholder={"From date"}
                name={"ddr_from_date"}
                type="date"
                value={data["ddr_from_date"] || ""}
                // handleInputchange={handleInputchange}
                handleInputchange={handleFromDateChange}
                classname={`w-400 ddr_from_date`}
                // min={fromDatedata}
                // max={data["ddr_to_date"]}
                max={formatDate()}
              />
            </div>
            <div>
              <InputField
                placeholder={"To date"}
                name={"ddr_to_date"}
                type="date"
                value={data["ddr_to_date"] || ""}
                handleInputchange={handleInputchange}
                classname={`w-400 ddr_to_date`}
                //max={formatDate()}
                // min={data["ddr_from_date"]}
                //max={data["ddr_to_date"]}
                min={data["ddr_from_date"]}
                max={calculateToDate(data["ddr_from_date"])}
              />
            </div>

            <div style={{marginTop:"25px"}}>
                <Button className="contactPersondata" onClick={handleLoad}>
                  Load
                </Button>
              </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-12 d-flex ddr_contnt">
            <div className="DDRRecivedSearchRow">
              <div className="common-select-space inline w-400">
                <BasicSelectDropDown
                  data={formatedSelect(priority?.data)}
                  label="Priority"
                  name="ddr_priority"
                  handleInputchange={handleInputchange}
                />
              </div>

              <div className="common-select-space inline">
                <Button className="contactPersondata" onClick={handleLoad}>
                  Load
                </Button>
              </div>
            </div>
          </div>
        </div> */}

        <div className="row">
          <div className="col-md-12 d-flex ddr_contnt justify-content-end consortia_ddr_mrg">
            <div>
              <InputField
                placeholder={"Search DDR ID"}
                name={"ddr_id"}
                handleInputchange={handleInputchange}
                classname={`w-200 ddr_id`}
                onKeyPress={(event) => {
                  if (event.key === "Enter") {
                    handleSearchIcon();
                  }
                }}
              />
              <i
                className="fa fa-search ddr_search_icn"
                onClick={handleSearchIcon}
              ></i>
            </div>
          </div>
        </div>
      </div>
      <div>
        {loading && <DDRLoading />}
        <DDRSentArticleTable
          tableData={tableDataDDRR && tableDataDDRR}
          customerID={customerID}
          currentPage={currentPage}
          recordsPerPage={recordsPerPage}
          linkResolverData={linkResolverData}
          numOfRecords={numOfRecords}
          currentStatus={currentStatus}
        />
        <div style={{ marginBottom: "30px" }}>
          <DDRPagination
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            setRecordsPerPage={setRecordsPerPage}
            tableDataReport={tableDataDDRR && tableDataDDRR}
            recordsPerPage={recordsPerPage}
            numOfPages={numOfPages}
          />
        </div>
        <div className="ScrollTopArrow">
          <ScrollTopArrow />
        </div>
      </div>
    </div>
  );
};

export default ConsortiaDDRSend;

import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import _ from "lodash";
import InputField from "../../atoms/InputField/InputField";
import Radio from "../../atoms/Radio/Radio";
import {
  ddrReportKeys,
  requestRRSelectData,
  get_dat_region_list,
  getDDRReport,
  exportReceivedDDRListUrl,
  exportSentDDRListUrl,
  formatedObject,
} from "../../containers/Reports/constants";
import { getConsortiaMemberList } from "../../containers/DDR/constants";
import CommonHeading from "../../atoms/Heading/CommonHeading";
import { useFetch } from "../../utilities/apiFunctions/useFetch";
import { fetchUrl } from "../../utilities/apiFunctions/fatchUrl";
import Loading from "../../components/Loading/Loading";
import { baseUrl } from "../../utilities/constants";
import { Pagination } from "../ReportsComponents/Pagination";
import InputFieldDDR from "../../atoms/InputField/InputFieldDDR";
import BasicSelectDropDown from "../../atoms/DropDown/BasicSelectDropDown";
import SelectDropDownWithoutSelectOption from "../../atoms/DropDown/DropDownNoSelectOption";
import DDRReportTable from "../Table/SimpleTable";
import { calculateToDate } from "../../utilities/formatedSelect";

const ConsortiaDDRReport = ({ customerID, consortiamaster_id, from }) => {
  const [fromDatedata, setFromDatedata] = useState("");
  let currentyear = new Date().getFullYear();
  let fromDateFormate = `${currentyear}-01-01`;
  const [data, setData] = useState({
    report_type: "1",
    // ddr_from_date: "",
    ddr_from_date: fromDateFormate.toString(),
    ddr_to_date: formatDate(),
    zone_id_val: "0",
    consortia_member_id: "0",
    export_type: "excel",
    report_format: "1",
    customer_id: customerID,
  });

  let memberId;
  if (data["report_type"] === "1") {
    memberId = "1";
  } else {
    memberId = "2";
  }

  const [tableDataReport, setTableDataReport] = useState([]);

  let startingId = 1;
  for (let i = 0; i <= tableDataReport.length - 2; i++) {
    tableDataReport[i]["id"] = startingId;
    startingId++;
  }

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    tableDataReport &&
    tableDataReport.slice(indexOfFirstRecord, indexOfLastRecord);
  const nPages = Math.ceil(
    tableDataReport && tableDataReport.length / recordsPerPage
  );

  const [isFromdateLoad, setIsFromdateLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isFromDate, setIsFromDate] = useState(false);
  const [isRequestReceived, setIsRequestReceived] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);
  const [instituteMemberId, setInstituteMemberId] = useState("");
  const [isRequestReceivedFormat, setIsRequestReceivedFormat] = useState(false);
  const [isRequestSentFormat, setIsRequestSentFormate] = useState(false);
  const report_format = [
    { label: "All", value: "1" },
    { label: "Fulfillment Report", value: "2" },
  ];

  console.log("instituteMemberId", instituteMemberId);
  function formatDate() {
    var d = new Date(),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  const fromDateResponse = async () => {
    if (data["consortia_member_id"] === "") {
      data["consortia_member_id"] = "0";
    }

    // let customer_id;
    // if (!isFromdateLoad) {
    //   customer_id = customerID;
    // } else {
    //   customer_id = instituteMemberId;
    // }

    if (from === "library") {
      let customer_id;
      if (instituteMemberId == "") {
        customer_id = customerID;
      } else {
        customer_id = instituteMemberId;
      }

      await fetch(
        `${baseUrl}/api/get_client_wise_sent_ddr_from_date/get_client_wise_sent_ddr_from_date?customer_id=${customer_id}&member_id=${memberId}`
      )
        .then((response) => response.json())
        .then((val) => {
          setFromDatedata(val?.data[0][0]?.from_date);
          setIsFromDate(true);
        });
    }

    if (from === "consortia") {
      await fetch(
        `${baseUrl}/api/get_client_wise_sent_ddr_from_date/get_client_wise_sent_ddr_from_date?customer_id=${instituteMemberId}&member_id=${memberId}`
      )
        .then((response) => response.json())
        .then((val) => {
          setFromDatedata(val?.data[0][0]?.from_date);
          setIsFromDate(true);
        });
    }
  };

  // useEffect(()=>{
  //   fromDateResponse();
  // },[isFromdateLoad])

  useEffect(() => {
    setData({
      report_type: data && data?.report_type,
      // ddr_from_date: fromDatedata,
      ddr_from_date: data && data?.ddr_from_date,
      ddr_to_date: formatDate(),
      zone_id_val: data && data?.zone_id_val,
      consortia_member_id: data && data?.consortia_member_id,
      export_type: data && data?.export_type,
      report_format: data && data?.report_format,
      customer_id: data && data?.customer_id,
    });
  }, [fromDatedata, isFromdateLoad]);

  useEffect(() => {
    // if (isFromDate) {
    //   handleLoad();
    // }

    handleLoad();
  }, [isFromDate]);

  const consortiaMemberList = useFetch(
    getConsortiaMemberList(consortiamaster_id),
    {}
  );

  const select_region_fetch = useFetch(get_dat_region_list, {});

  const handleLoad = async () => {
    setLoading(true);

    if (data["report_type"] === "1") {
      setIsRequestReceived(true);
      setIsRequestSent(false);
    }

    if (data["report_type"] === "2") {
      setIsRequestReceived(false);
      setIsRequestSent(true);
    }

    if (data["report_format"] === "1") {
      setIsRequestReceivedFormat(true);
      setIsRequestSentFormate(false);
    }

    if (data["report_format"] === "2") {
      setIsRequestReceivedFormat(false);
      setIsRequestSentFormate(true);
    }

    setCurrentPage(1);
    const _dat = _.cloneDeep(data);
    // _dat["customer_id"] = customerID;
    _dat["consortia_id"] = consortiamaster_id;

    // if (_dat["consortia_member_id"] === "") {
    //   _dat["consortia_member_id"] = customerID
    // }

    if (_dat["zone_id_val"] === "") {
      _dat["zone_id_val"] = "0";
    }
    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");

    console.log("str", str);

    const response = await fetchUrl(getDDRReport(str));
    setTableDataReport(response.data[0][0].DDR_Report_List || []);
    setLoading(false);
  };

  const handleInputchange = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    setData(_dat);
  };

  const handleInputchangeInstitute = (value, name) => {
    const _dat = _.cloneDeep(data);
    _dat[name] = value;
    if (value == "") {
      _dat["customer_id"] = customerID;
    }
    setInstituteMemberId(value);
    setData(_dat);
    setIsFromdateLoad(!isFromdateLoad);
  };

  const handleExport = async () => {
    const _dat = _.cloneDeep(data);
    // _dat["customer_id"] = customerID;
    _dat["consortia_id"] = consortiamaster_id;

    if (_dat["zone_id_val"] === "") {
      _dat["zone_id_val"] = "0";
    }

    let str = Object.entries(_dat)
      .map(([key, val]) => `${key}${val ? "=" : ""}${val}`)
      .join("&");
    if (data["report_type"] === "1") {
      window.open(exportReceivedDDRListUrl(str));
    } else {
      window.open(exportSentDDRListUrl(str));
    }
  };

  const handleFromDateChange = (value) => {
    const _dat = _.cloneDeep(data);
    _dat["ddr_from_date"] = value;

    const fromDate = new Date(value);
    const toDate = new Date(fromDate);
    toDate.setDate(fromDate.getDate() + 365);

    const today = new Date();
    if (toDate > today) {
      _dat["ddr_to_date"] = formatDate(today);
    } else {
      _dat["ddr_to_date"] = toDate.toISOString().split("T")[0];
    }

    setData(_dat);
  };

  return (
    <div className="CountuReport">
      <CommonHeading heading="DDR Report" />

      <div>
        <div className="row">
        <div className="col-md-12 d-flex">
            <span style={{ color: "red" }}>
              <strong>
                The selected date must be within 12-months or 1-year range.
              </strong>
            </span>
          </div>
          <div className="col-md-12 d-flex">
            {consortiaMemberList.isLoading && <Loading />}
            {!consortiaMemberList.isLoading &&
              consortiaMemberList.response &&
              !!consortiaMemberList.response.data && (
                <div className="common-select-space w-300 inline">
                  <BasicSelectDropDown
                    defaultSelectValue={data["customer_id"]}
                    data={formatedObject(consortiaMemberList.response.data)}
                    // name="consortia_member_id"
                    name="customer_id"
                    label="Institution"
                    handleInputchange={handleInputchangeInstitute}
                  />
                </div>
              )}
            {/* <div className="ddr_ddr_reprtyp">
              <InputField
                placeholder={"From date"}
                name={"ddr_from_date"}
                type="date"
                value={data["ddr_from_date"] || ""}
                // handleInputchange={handleInputchange}
                handleInputchange={handleFromDateChange}
                classname={`w-200 ddr_from_date`}
                // min={fromDatedata}
                // max={data["ddr_to_date"]}
                max={formatDate()}
              />
            </div>
            <div className="ddr_ddr_reprtyp">
              <InputFieldDDR
                placeholder={"To date"}
                name={"ddr_to_date"}
                type="date"
                value={data["ddr_to_date"] || ""}
                handleInputchange={handleInputchange}
                classname={`w-200 ddr_to_date contactPersondata`}
                // max={formatDate()}
                // min={data["ddr_from_date"]}
                parentComponet="dates"
                max={data["ddr_to_date"]}
                min={data["ddr_from_date"]}
              />
            </div> */}

            <div className="ddr_ddr_reprtyp">
              <InputField
                placeholder={"From date"}
                name={"ddr_from_date"}
                type="date"
                value={data["ddr_from_date"] || ""}
                // handleInputchange={handleInputchange}
                handleInputchange={handleFromDateChange}
                classname={`w-200 ddr_from_date`}
                // min={fromDatedata}
                // max={data["ddr_to_date"]}
                max={formatDate()}
              />
            </div>
            <div className="ddr_ddr_reprtyp">
              <InputFieldDDR
                placeholder={"To date"}
                name={"ddr_to_date"}
                type="date"
                value={data["ddr_to_date"] || ""}
                handleInputchange={handleInputchange}
                classname={`w-200 ddr_to_date contactPersondata`}
                //max={formatDate()}
                // min={data["ddr_from_date"]}
                parentComponet="dates"
                //max={data["ddr_to_date"]}
                min={data["ddr_from_date"]}
                max={calculateToDate(data["ddr_from_date"])}
              />
            </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-12 d-flex">
            <span style={{ color: "red" }}>
              <strong>
                The selected date must be within 12-months or 1-year range.
              </strong>
            </span>
          </div>
        </div> */}
        {/* <div className="row">
          <div className="col-md-12 d-flex">
            <div className="">
              <InputField
                placeholder={"From date"}
                name={"ddr_from_date"}
                type="date"
                value={data["ddr_from_date"] || ""}
                // handleInputchange={handleInputchange}
                handleInputchange={handleFromDateChange}
                classname={`w-300 ddr_from_date`}
                // min={fromDatedata}
                // max={data["ddr_to_date"]}
                max={formatDate()}
              />
            </div>
            <div className="ddr_ddr_reprtyp">
              <InputFieldDDR
                placeholder={"To date"}
                name={"ddr_to_date"}
                type="date"
                value={data["ddr_to_date"] || ""}
                handleInputchange={handleInputchange}
                classname={`w-300 ddr_to_date contactPersondata`}
                //max={formatDate()}
                // min={data["ddr_from_date"]}
                parentComponet="dates"
                //max={data["ddr_to_date"]}
                min={data["ddr_from_date"]}
                max={calculateToDate(data["ddr_from_date"])}
              />
            </div>
          </div>
        </div> */}
        <div className="col-md-12 d-flex">
          <div>
            <div className="common-select-space w-200 inline">
              <SelectDropDownWithoutSelectOption
                defaultSelectValue={data["report_type"]}
                data={requestRRSelectData}
                name="report_type"
                label="Select Report Type"
                handleInputchange={handleInputchange}
              />
            </div>
          </div>
          <div className="ddr_ddr_reprtyp">
            <div className="common-select-space w-150 inline">
              {select_region_fetch.isLoading && <Loading />}
              {!select_region_fetch.isLoading &&
                select_region_fetch.response &&
                !!select_region_fetch.response.data && (
                  <BasicSelectDropDown
                    data={formatedObject(select_region_fetch.response.data)}
                    name="zone_id_val"
                    label="Select Region"
                    handleInputchange={handleInputchange}
                    parentComponet="dates"
                  />
                )}
            </div>
          </div>
          <div className="ddr_ddr_reprtyp">
          <div className="col-md-12 d-flex">
            <div className="common-select-space w-150 inline">
              <SelectDropDownWithoutSelectOption
                defaultSelectValue={data["report_format"]}
                data={report_format}
                name="report_format"
                label="Select Format"
                handleInputchange={handleInputchange}
              />
            </div>
            <div>
              <Button
                onClick={handleLoad}
                className="loadButton contactPersondata"
              >
                Load
              </Button>
            </div>
          </div>
          </div>
        </div>
        {/* <div className="row">
          <div className="col-md-12 d-flex">
            <div className="common-select-space w-200 inline">
              <SelectDropDownWithoutSelectOption
                defaultSelectValue={data["report_format"]}
                data={report_format}
                name="report_format"
                label="Select Format"
                handleInputchange={handleInputchange}
              />
            </div>
            <div>
              <Button
                onClick={handleLoad}
                className="loadButton contactPersondata"
              >
                Load
              </Button>
            </div>
          </div>
        </div> */}
        <div className="row ">
          <div className="col-md-12 ddr_ddr_loadbtn">
            <div className="CountuReportExport">
              <Radio
                label={"Excel"}
                value={"excel"}
                name="export_type"
                checkedValue={data["export_type"]}
                handleInputchange={handleInputchange}
              />
              <Radio
                label={"CSV"}
                value={"csv"}
                name="export_type"
                checkedValue={data["export_type"]}
                handleInputchange={handleInputchange}
              />

              <Button onClick={handleExport} className="contactPersondata">
                Export
              </Button>
            </div>
          </div>
        </div>
      </div>

      <div className="CountuReportRow">
        {loading && <Loading />}
        <DDRReportTable
          tableProperty={ddrReportKeys}
          tableData={currentRecords}
          isIndexed="true"
          isRequestReceived={isRequestReceived}
          isRequestSent={isRequestSent}
          isRequestReceivedFormat={isRequestReceivedFormat}
          isRequestSentFormat={isRequestSentFormat}
        />
      </div>
      <div>
        <Pagination
          nPages={nPages && nPages}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          setRecordsPerPage={setRecordsPerPage}
          tableDataReport={tableDataReport}
          recordsPerPage={recordsPerPage}
        />
      </div>
    </div>
  );
};

export default ConsortiaDDRReport;

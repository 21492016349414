import React, { useState, useEffect } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Login from "./containers/Login/Login";
import Logout from "./containers/Login/Logout";
import Dashboard from "./containers/Dashboard/Dashboard";
import "./styles/index.css";
import { ProtectedRoute } from "./utilities/ProtectedRoute";
import { getLocalStorage } from "./utilities/storage";
import Page404 from "./containers/Other/Page404";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import Sidebar from "./components/Sidebar/Sidebar";
import OrgDetails from "./containers/OrgDetails/OrgDetails";
import ResourceMgmt from "./containers/ResourceMgmt/ResourceMgmt";
import Reports from "./containers/Reports/Reports";
import UserProfile from "./containers/UsersProfile/UsersProfile";
import DDR from "./containers/DDR/DDR";
import MemberLibraries from "./containers/MemberLibraries/MemberLibraries";
import UserUpload from "./components/UserUploads/UserUpload";
import DefaultHeader from "./components/Header/DefaultHeader";
import { Aboutus } from "./components/Footer/AboutUs";
import { FAQ } from "./components/Footer/FAQ";
import { Contactus } from "./components/Footer/ContactUs";
import { TransferToLibrary } from "./components/Footer/TransfertoLibrary";
import { LicencedPublisherData } from "./components/Footer/PublisherList";

function App() {
  const [user, setUser] = useState(getLocalStorage("user"));
  const [customer, setCustomer] = useState(getLocalStorage("customer") || {});
  const [loading, setLoading] = useState(false);
  const [isAppHeader, setIsAppHeader] = useState(true);
  const [isSidebar, setIsSidebar] = useState(false);

  const getCustomer = () => customer;

  useEffect(() => {
    if (
      window.location.pathname === "/contactus" ||
      window.location.pathname === "/aboutus" ||
      window.location.pathname === "/faq" ||
      window.location.pathname === "/licencedpublisherlist"
    ) {
      setIsSidebar(true);
    }
  }, [isSidebar]);

  useEffect(() => {
    if (
      window.location.pathname === "/contactus" ||
      window.location.pathname === "/aboutus" ||
      window.location.pathname === "/faq" ||
      window.location.pathname === "/licencedpublisherlist"
    ) {
      setIsAppHeader(false);
    }
  }, [isAppHeader]);

  if (!user) {
    return (
      <>
        <DefaultHeader />
        <Switch>
          <Route exact path="/">
            <Login
              setUser={setUser}
              setCustomer={setCustomer}
              setLoading={setLoading}
            />
          </Route>
          <Route exact path="/login">
            <Login
              setUser={setUser}
              setCustomer={setCustomer}
              setLoading={setLoading}
            />
          </Route>
          <Route exact path="/transfertolibrary">
            <TransferToLibrary />
          </Route>
          <Route exact path="/aboutus">
            <Aboutus />
          </Route>
          <Route exact path="/faq">
            <FAQ />
          </Route>
          <Route exact path="/contactus">
            <Contactus />
          </Route>
          <Route exact path="/licencedpublisherlist">
            <LicencedPublisherData />
          </Route>
        </Switch>
        <Footer />
      </>
    );
  }

  return (
    <div className="App">
      <div>
        {!loading && isAppHeader ? (
          <Header user={user} customer={customer} getCustomer={getCustomer} />
        ) : (
          <DefaultHeader
          />
        )}
        <BrowserRouter>
          <div className="AppMain">
            {!isSidebar && (
              <aside className="AppAside">
                <Sidebar user={user} customer={customer} />
              </aside>
            )}
            <main>
              <Switch>
                <ProtectedRoute as={OrgDetails} role={1} path="/orgDetails" />
                <ProtectedRoute
                  user={user}
                  as={MemberLibraries}
                  role={1}
                  path="/memberLibraries"
                />
                <ProtectedRoute
                  as={ResourceMgmt}
                  role={1}
                  path="/ResourceMgmt"
                />
                <ProtectedRoute as={DDR} role={1} path="/DDR" />
                <ProtectedRoute
                  as={UserProfile}
                  role={1}
                  path="/UsersProfile"
                />
                <ProtectedRoute as={UserUpload} role={1} path="/UserUpload" />
                <ProtectedRoute as={Reports} role={1} path="/Reports" />
                <ProtectedRoute as={Logout} role={1} path="/Logout" />
                <ProtectedRoute as={Aboutus} role={1} path="/aboutus" />
                <ProtectedRoute as={FAQ} role={1} path="/faq" />
                <ProtectedRoute as={Contactus} role={1} path="/contactus" />
                <ProtectedRoute as={Dashboard} role={1} path="/dashboard" />
                <ProtectedRoute as={Dashboard} role={1} path="/" exact />
                 <ProtectedRoute as={LicencedPublisherData} role={1} path="/licencedpublisherlist" />
                <ProtectedRoute as={Page404} role={1} path="/**" />              </Switch>
            </main>
          </div>
        </BrowserRouter>
      </div>
      <Footer />
    </div>
  );
}

export default App;

import React,{useState} from 'react'
import { useEffect } from 'react';
import { fetchUrl } from '../../utilities/apiFunctions/fatchUrl';
import { customerByIDUrl, validateLibraryAdminLoginByToken } from '../../utilities/constants';
//import { useNavigate,Link } from "react-router-dom";
//import { useNavigate } from 'react-router-dom';
//import { Navigate } from "react-router-dom";
import { useHistory} from "react-router-dom";
import { setLocalStorage } from "../../utilities/storage";
import { removeLocalStorage, removeAllStorage } from '../../utilities/storage';
//import { generateSessionID } from '../../utilities/generateSessionID';




export function TransferToLibrary() {
    const history = useHistory();
   // const navigate = useNavigate();
const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
//const [prevUrl, setPrevUrl] = useState(window.location.href);
//console.log("prevUrl",prevUrl)

// Get individual parameters and their values
const customer_id = urlParams.get('customer_id');
const login_token = urlParams.get('login_token');

// console.log("customer_id",customer_id)

// const handleClearLocalStorage = () => {
//     // Clear localStorage
//     localStorage.clear();
//     console.log('localStorage has been cleared.');
//        removeLocalStorage("user");
//        removeLocalStorage("customer");
//        setLocalStorage("user", []);
//        setLocalStorage("customer", []);
//       // removeAllStorage();
//   };

useEffect(()=>{
    removeLocalStorage("user");
    removeLocalStorage("customer");
    removeAllStorage();
    //localStorage.clear();
    //setLocalStorage("user", {});
    //setLocalStorage("customer", {});
    
    const timer = setTimeout(() => {
        handleDiskoverSearch();
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
},[])



const handleDiskoverSearch =async ()=>{ 
    const formData = new FormData();
    formData.append("customerid", customer_id);
    formData.append("token", login_token);
    const options = { method: "POST", body: formData };
     const data = await fetchUrl(validateLibraryAdminLoginByToken, options);
    // console.log("data",data, typeof(data))
     if(data && data.data === "Success"){
        setLocalStorage("user", {
            userID: data.user_id,
            customerID: data.informaticscustomer_id,
            sessionID: data.session_key, 
            //sessionID:generateSessionID(),
            admin_type: data.admin_type,
          });
    
          const customerDetails = await fetchUrl(
            customerByIDUrl(customer_id)
          );
          const _customer = customerDetails.data[0][0];
          setLocalStorage("customer", _customer);
        //   setUser({
        //     userID: data.user_id,
        //     admin_type: data.admin_type,
        //     customerID: data.informaticscustomer_id,
        //   });
        //   setCustomer(_customer);

        history.push("/dashboard");
        // window.location.href='/dashboard'
        window.location.reload(true);

     }else{
        LogOutfn();
        //history.push("/login");
        //window.location.href='/login'
     }
    }

const LogOutfn = () => {
    removeLocalStorage("user");
    removeLocalStorage("customer");
    removeAllStorage();
    // window.location.href = '/';
    history.push("/login");
  };

    return (
        <div> Transfor to Library Admin</div>
    )
}

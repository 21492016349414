
import React, { useEffect, useState } from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationComponent = ({
  numOfPages, // total number of pages passed from parent
  currentPage,
  setCurrentPage,
  onPageChange,
}) => {
  const [arrOfCurrentButtons, setArrOfCurrentButtons] = useState([]);

  // useEffect(() => {
  //   const totalPages = numOfPages; // Total pages passed from parent
  //   let tempNumberOfPages = [];
  //   const dotsInitial = "...";
  //   const dotsLeft = "... ";
  //   const dotsRight = " ...";

  //   // Case 1: If the number of pages is less than 6, show all page numbers
  //   if (totalPages < 6) {
  //     tempNumberOfPages = Array.from({ length: totalPages }, (_, i) => i + 1);
  //   }

  //   // Case 2: Show the first 4 pages + ellipsis + last page
  //   else if (currentPage >= 1 && currentPage <= 3) {
  //     tempNumberOfPages = [1, 2, 3, 4, dotsInitial, totalPages];
  //   }

  //   // Case 3: If the current page is page 4, show pages 1-5 + ellipsis + last page
  //   else if (currentPage === 4) {
  //     tempNumberOfPages = [1, 2, 3, 4, 5, dotsInitial, totalPages];
  //   }

  //   // Case 4: If in the middle range (not close to the start or end), show a range of 2 pages before and 2 pages after
  //   else if (currentPage > 4 && currentPage < totalPages - 2) {
  //     const sliced1 = Array.from({ length: 2 }, (_, i) => currentPage - 2 + i); // Pages before the current page
  //     const sliced2 = Array.from({ length: 2 }, (_, i) => currentPage + 1 + i); // Pages after the current page
  //     tempNumberOfPages = [
  //       1,
  //       dotsLeft,
  //       ...sliced1,
  //       currentPage, // Keep current page as active but don't add it twice in the list
  //       ...sliced2,
  //       dotsRight,
  //       totalPages,
  //     ];
  //   }

  //   // Case 5: For the last pages, show first page, left ellipsis, and last pages
  //   else if (currentPage > totalPages - 3) {
  //     const sliced = Array.from({ length: 4 }, (_, i) => totalPages - 4 + i); // Show the last 4 pages
  //     tempNumberOfPages = [1, dotsLeft, ...sliced];
  //   }

  //   setArrOfCurrentButtons(tempNumberOfPages);

  // }, [currentPage, numOfPages]); // Updated dependency

  useEffect(() => {
    let tempNumberOfPages = [];
    const totalPages = numOfPages || 1; // Default to 1 if numOfPages is not provided

    const dotsInitial = "...";
    const dotsLeft = "... ";
    const dotsRight = " ...";

    if (totalPages <= 5) {
        tempNumberOfPages = Array.from({ length: totalPages }, (_, i) => i + 1);
    } else if (currentPage >= 1 && currentPage <= 3) {
        tempNumberOfPages = [1, 2, 3, 4, dotsRight, totalPages];
    } else if (currentPage === 4) {
        tempNumberOfPages = [1, 2, 3, 4, 5, dotsRight, totalPages];
    } else if (currentPage > 4 && currentPage < totalPages - 2) {
        tempNumberOfPages = [
            1,
            dotsLeft,
            currentPage - 1,
            currentPage,
            currentPage + 1,
            dotsRight,
            totalPages,
        ];
    } else if (currentPage >= totalPages - 2) {
        tempNumberOfPages = [
            1,
            dotsLeft,
            totalPages - 3,
            totalPages - 2,
            totalPages - 1,
            totalPages,
        ];
    }

    setArrOfCurrentButtons(tempNumberOfPages);
}, [currentPage, numOfPages]);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > numOfPages) return; // Prevent invalid page numbers
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  return (
    <div className="d-flex justify-content-end">
      <Pagination>
        <Pagination.First
          disabled={currentPage === 1}
          onClick={() => handlePageChange(1)}
        />
        <Pagination.Prev
          disabled={currentPage === 1}
          onClick={() => handlePageChange(currentPage - 1)}
        />
        {arrOfCurrentButtons.map((page, index) => (
          <Pagination.Item
            key={index}
            active={page === currentPage}  // Mark the current page as active
            onClick={() => typeof page === "number" && handlePageChange(page)} // Only allow page numbers to trigger page change
          >
            {page}
          </Pagination.Item>
        ))}
        <Pagination.Next
          disabled={currentPage === numOfPages}
          onClick={() => handlePageChange(currentPage + 1)}
        />
        <Pagination.Last
          disabled={currentPage === numOfPages}
          onClick={() => handlePageChange(numOfPages)}
        />
      </Pagination>
    </div>
  );
};

export default PaginationComponent;





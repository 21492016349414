import React from 'react';

export const BrowserCompatibility =()=>{
    return(
        <div>
            <div>1. Microsoft Edge <span style={{color:"blue"}}>104+</span> </div>
            <div>2. Firefox <span style={{color:"blue"}}>91+</span></div>
            <div>3. Google Chrome <span style={{color:"blue"}}>104+</span></div>
            <div>4. Safari <span style={{color:"blue"}}>15+</span></div>
        </div>
    )
}
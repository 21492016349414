export const formatedSelect = (data) => {
  const _dat = data && data[0][0];
  const _data =
    _dat &&
    _dat?.map((item) => {
      const a = {};
      a["value"] = Object.keys(item)[0];
      a["label"] = Object.values(item)[0];
      return a;
    });

  return _data;
};

export const mapSelectData = (data, key) => {
  const _dat = data && data[0][0];
  const _data = _dat?.map((item) => {
    const a = {};
    a["value"] = item[key["value"]];
    a["label"] = item[key["label"]];
    return a;
  });

  return _data;
};

// Helper function to check if a year is a leap year
function isLeapYear(year) {
  return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
}
export function calculateToDate(fromDate) {
  const d = new Date(fromDate);
  const currentDate = new Date();

  // Add 1 year to the 'fromDate'
  let toDate = new Date(d.setFullYear(d.getFullYear() + 1));

  // Adjust for leap years: If the resulting day is February 29, it should become February 28 if the target year is not a leap year.
  if (d.getMonth() === 1 && d.getDate() === 29 && !isLeapYear(toDate.getFullYear())) {
    toDate.setDate(28);
  }

  // If the calculated 'toDate' exceeds the current date, set 'toDate' to the current date
  if (toDate > currentDate) {
    toDate = currentDate;
  }

  // Format the date as YYYY-MM-DD
  const year = toDate.getFullYear();
  const month = String(toDate.getMonth() + 1).padStart(2, '0');
  const day = String(toDate.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
}


export const handleStatusChange = (_dat,setCurrentStatus ) => {
  switch (_dat["ddr_status"]) {
    case "":
    case "0":
      setCurrentStatus("All");
      break;
    case "1":
      setCurrentStatus("Pending");
      break;
    case "2":
      setCurrentStatus("Fulfilled Request");
      break;
    case "3":
      setCurrentStatus("Delayed");
      break;
    case "4":
      setCurrentStatus("Article not Available");
      break;
    case "5":
      setCurrentStatus("Discarded");
      break;
    case "6":
      setCurrentStatus("Delivered outside the DDR system");
      break;
    case "7":
      setCurrentStatus("Duplicate Request");
      break;
    default:
      setCurrentStatus("Pending");
  }
};

import React from "react";
import { Container } from "react-bootstrap";
import "./Footer.css";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";


// aboutus screen function
export const Aboutus = () => {
  return (
    <div className="main_about">
      {/* <Container>
        <h4 className="headermargin"> Informatics Publishing India Pvt Ltd</h4>

        <p>
          The test for coronavirus does not involve any blood examination.
          Coronavirus test includes examining of the throat swab or the nasal
          swab to check for infection. After taking samples, doctors posted at
          nodal hospitals may assess your physical health to decide whether
          hospitalisation is needed. Otherwise, you may be asked to stay
          isolated at home itself. If the tests are positive, you may require to
          remain quarantined for at least 14 days, till recovery.
        </p>
        <p>
          Call Union health ministry's 24X7 helpline number: 01123978046. You
          can also mail your queries at ncov2019@gmail.com. The district
          surveillance officer will visit you shortly and, if chances of
          infection are high, you may be transported to a designated hospital
          for the test. Govt has placed separate ambulances for transporting
          suspected cases. Use of public transport isn't advised.
        </p>
        <p>
          It's not known. A few reports from China say some people had COVID-19,
          recovered and then fell ill again. It's unclear if that's a relapse, a
          new infection, or a case where the person never fully recovered in the
          first place. Scientists at the Fred Hutchinson Cancer Research Center
          in Seattle say the 30,000-letter genetic code of the virus changes by
          one letter every 15 days. It's not known how many of these changes
          would be needed for the virus to seem different enough to the immune
          system of someone who had a previous version of it for it to cause a
          fresh infection.
        </p>
        <p>
          The test for coronavirus does not involve any blood examination.
          Coronavirus test includes examining of the throat swab or the nasal
          swab to check for infection. After taking samples, doctors posted at
          nodal hospitals may assess your physical health to decide whether
          hospitalisation is needed. Otherwise, you may be asked to stay
          isolated at home itself. If the tests are positive, you may require to
          remain quarantined for at least 14 days, till recovery.
        </p>
        <p>
          Call Union health ministry's 24X7 helpline number: 01123978046. You
          can also mail your queries at ncov2019@gmail.com. The district
          surveillance officer will visit you shortly and, if chances of
          infection are high, you may be transported to a designated hospital
          for the test. Govt has placed separate ambulances for transporting
          suspected cases. Use of public transport isn't advised.
        </p>
        <p>
          It's not known. A few reports from China say some people had COVID-19,
          recovered and then fell ill again. It's unclear if that's a relapse, a
          new infection, or a case where the person never fully recovered in the
          first place. Scientists at the Fred Hutchinson Cancer Research Center
          in Seattle say the 30,000-letter genetic code of the virus changes by
          one letter every 15 days. It's not known how many of these changes
          would be needed for the virus to seem different enough to the immune
          system of someone who had a previous version of it for it to cause a
          fresh infection.
        </p>
        <p>
          The test for coronavirus does not involve any blood examination.
          Coronavirus test includes examining of the throat swab or the nasal
          swab to check for infection. After taking samples, doctors posted at
          nodal hospitals may assess your physical health to decide whether
          hospitalisation is needed. Otherwise, you may be asked to stay
          isolated at home itself. If the tests are positive, you may require to
          remain quarantined for at least 14 days, till recovery.
        </p>
        <p>
          Call Union health ministry's 24X7 helpline number: 01123978046. You
          can also mail your queries at ncov2019@gmail.com. The district
          surveillance officer will visit you shortly and, if chances of
          infection are high, you may be transported to a designated hospital
          for the test. Govt has placed separate ambulances for transporting
          suspected cases. Use of public transport isn't advised.
        </p>
        <p>
          It's not known. A few reports from China say some people had COVID-19,
          recovered and then fell ill again. It's unclear if that's a relapse, a
          new infection, or a case where the person never fully recovered in the
          first place. Scientists at the Fred Hutchinson Cancer Research Center
          in Seattle say the 30,000-letter genetic code of the virus changes by
          one letter every 15 days. It's not known how many of these changes
          would be needed for the virus to seem different enough to the immune
          system of someone who had a previous version of it for it to cause a
          fresh infection.
        </p>
        <p>
          The test for coronavirus does not involve any blood examination.
          Coronavirus test includes examining of the throat swab or the nasal
          swab to check for infection. After taking samples, doctors posted at
          nodal hospitals may assess your physical health to decide whether
          hospitalisation is needed. Otherwise, you may be asked to stay
          isolated at home itself. If the tests are positive, you may require to
          remain quarantined for at least 14 days, till recovery.
        </p>
        <p>
          Call Union health ministry's 24X7 helpline number: 01123978046. You
          can also mail your queries at ncov2019@gmail.com. The district
          surveillance officer will visit you shortly and, if chances of
          infection are high, you may be transported to a designated hospital
          for the test. Govt has placed separate ambulances for transporting
          suspected cases. Use of public transport isn't advised.
        </p>
        <p className="footer_extra_margin">
          It's not known. A few reports from China say some people had COVID-19,
          recovered and then fell ill again. It's unclear if that's a relapse, a
          new infection, or a case where the person never fully recovered in the
          first place. Scientists at the Fred Hutchinson Cancer Research Center
          in Seattle say the 30,000-letter genetic code of the virus changes by
          one letter every 15 days. It's not known how many of these changes
          would be needed for the virus to seem different enough to the immune
          system of someone who had a previous version of it for it to cause a
          fresh infection.
        </p>
      </Container> */}
      <Container>
        <Tab.Container id="left-tabs-example" defaultActiveKey="1">
          <Row>
            <Col sm={3}>
              <Nav variant="pills" className="flex-column tabs">
                <Nav.Item>
                  <Nav.Link eventKey="1">What is J-Gate?</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="2">J-Gate Features and Benefits</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="3">J-Gate Products and Services</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="4">J-Gate Collaborations</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="5">J-Gate Infrastructure</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="6">Trial Access</Nav.Link>
                </Nav.Item>
              </Nav>
            </Col>
            <Col sm={9}>
              <Tab.Content>
                <Tab.Pane eventKey="1">
                  <div className="headingText headercolor">What is J-Gate?</div>
                  <div className="subText">
                    J-Gate functions as a electronic gateway access to a vast
                    array of e-journal content worldwide. Initially introduced
                    in 2001 by Informatics India Limited, this platform grants
                    users seamless entry to millions of online journal articles
                    sourced from 15,294 publishers. Presently, J-Gate boasts an
                    extensive database comprising literature from 67,487
                    periodical publications e-journals, Conference-Proceedings,
                    Book series) each with direct links to full-text articles
                    hosted on publisher websites. Additionally, J-Gate aims to
                    provide supplementary services including online journal
                    subscription, electronic document delivery, archival
                    facilities, and more. Key features of J-Gate include:
                    <div style={{ marginLeft: "20px" }}>
                      <div>
                        1. Enhanced Content Coverage: Access a wider range of
                        content than ever before, ensuring you have all the
                        information you need at your fingertips.
                      </div>
                      <div>
                        2. Table of Contents (TOC) - Covering 67, 487 periodical
                        publications.
                      </div>
                      <div>
                        3. Database - Offering a comprehensive searchable
                        database housing a staggering 83,565,438 articles, with
                        over 40,000 new articles incorporated daily.
                      </div>
                      <div>
                        4. New Search & Browse Experience: Our latest technology
                        provides a seamless and intuitive search and browse
                        experience, making it easier than ever to find what
                        you're looking for.
                      </div>
                      <div>
                        5. More Full text Coverage: Dive deeper into content
                        with expanded full-text coverage, allowing you to
                        explore topics comprehensively.
                      </div>
                      <div>
                        6. Mobile Applications (Android & iOS): Take your
                        experience on the go with our mobile applications,
                        available for both Android and iOS devices. Access your
                        favorite features anytime, anywhere.
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="2">
                  <div className="headingText headercolor">
                    J-Gate Features and Benefits
                  </div>
                  <div className="subText">
                    <div>
                      1. Dedicated e-Journal Portal: J-Gate exclusively
                      encompasses journals accessible online, ensuring a truly
                      digital repository
                    </div>
                    <div>
                      2. Largest Repository of e-Journals: As an e-journal
                      platform, J-Gate currently hosts content from an
                      impressive 67,487 periodic publications
                    </div>
                    <div>
                      3. Open-Access Journals: Curates and indexes articles from
                      over 35,479 open-access e-journals, maintaining direct
                      links to them.
                    </div>
                    <div>
                      4. Access to Millions of Open-Access Articles: Provides
                      access to a vast collection of over 27,194,042 open-access
                      articles.
                    </div>
                    <div>
                      5. Extensive Publisher Connectivity: Offers full-text
                      links to documents from a broad network of 15,294
                      publishers.
                    </div>
                    <div>
                      6. Union List Integration: The convenient Union List
                      feature allows J-Gate subscribers to verify journal
                      availability in local libraries.
                    </div>
                    <div>
                      7. Tailored Customization Options: Customization
                      capabilities enable libraries to configure the product
                      according to their specific needs, utilizing a range of
                      predefined features and functionalities.
                      <div style={{ marginLeft: "25px" }}>
                        <div>
                          a. Configure Journal subscriptions of the Institute to
                          get the customization of separate collection named “My
                          Library Collection”
                        </div>
                        <div>
                          b. Search & Browse only within the Library collection
                          to ensure to get Full Text of all the documents
                          subscribed by the Institute
                        </div>
                        <div>
                          c. Search & Browse only within Open access collection
                          of J-Gate for the subscribed package of J-Gate
                        </div>
                        <div>
                          d. Mark and collect your favourite journals /
                          documents in your personal library and search and
                          browse within the personal library
                        </div>
                        <div>
                          e. Mark for alert and get the regular updates for your
                          interested topic / journals
                        </div>
                        <div>
                          f. Save your search queries and receive updated
                          results for future reference
                        </div>
                      </div>
                    </div>
                    <div>
                      8. Explore customized author searches tailored to your
                      preferences
                    </div>
                    <div>
                      9. Utilize the search builder to conduct criteria-based
                      searches for precise analytics and refined outcomes
                    </div>
                    <div>
                      10. Visualize filters through graphical representations
                      for a rapid overview of search outcomes.
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="3">
                  <div className="headingText headercolor">
                    J-Gate Products and Services
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: "18px",
                      marginTop: "10px",
                    }}
                  >
                    J-Gate Database
                  </div>
                  <div className="subText">
                    <div>
                      1. Provides an extensive range of content encompassing
                      diverse document types
                    </div>
                    <div>2. Classifies subjects into three levels. </div>
                    <div>3. Provides subject-wise indexing. </div>
                    <div>
                      4. Searchable by Title, Author, Subject Categories,
                      Keywords, Year, Journal, Institutions, DOI etc.
                    </div>
                    <div>
                      5. Retrieve the ORCID information of the author and access
                      further details about the author from their ORCID profile
                    </div>
                    <div>
                      6. Includes basic bibliographic data with abstracts.{" "}
                    </div>
                    <div>
                      7. Author addresses and emails provided where available.
                    </div>
                    <div>
                      8. Direct links to full-text articles (both open-access
                      and subscription).
                    </div>
                    <div>
                      9. Union List link for availability checks in the form of
                      “Find in Library”.
                    </div>
                    <div>
                      10. Access to alternative full-text versions from preprint
                      sources for articles behind paywalls
                    </div>
                    <div>
                      11. Retrieve information on retracted articles for the
                      obtained results.
                    </div>
                    <div>
                      12. Retrieve information about articles mapping datasets
                      for the obtained results.
                    </div>
                    <div>
                      13. Implement a filter on the acquired results to
                      specifically showcase early online articles, if they are
                      accessible for the provided search term
                    </div>
                    <div>14. Daily updates with progressive accumulation. </div>
                    <div>
                      15. Browse and apply filters to the results to
                      specifically view articles from journals indexed in Web of
                      Science, Scopus, DOAJ etc
                    </div>
                    <div>16. Tailor the collection to your preferences by </div>
                    <div style={{ marginLeft: "50px" }}>
                      <div>
                        i. configuring subscriptions under 'My Library
                        Collections'
                      </div>
                      <div>
                        ii. Personalize your collection by bookmarking your
                        favorite journals or searched documents into your
                        personal library for future search and browsing
                        endeavors.
                      </div>
                    </div>
                  </div>
                  <div
                    style={{
                      fontWeight: 700,
                      fontSize: "18px",
                      marginTop: "10px",
                    }}
                  >
                    J-Gate@Consortia
                  </div>
                  <div className="subText">
                    <div>
                      <div>
                        1. Serves as a search platform and resource-sharing hub
                        for consortium members.
                      </div>
                      <div>
                        2. Customized coverage restricted to Consortium and
                        member library subscribed journals.
                      </div>
                      <div>
                        3. Exclusive service tailored for registered
                        closed-user-group members of the consortium.
                      </div>
                      <div style={{ marginBottom: "80px" }}>
                        4. Designed for resource and infrastructure sharing
                        among members through a mutual agreement.
                      </div>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="4">
                  <div className="headingText headercolor">
                    J-Gate Collaborations
                  </div>
                  <div className="subText">
                    J-Gate owes much of its success and robustness to its
                    partnerships with scholarly, technical, and key publishers
                    and third party aggregators. These collaborations, often
                    established through mutual agreements, form a cornerstone of
                    J-Gate's strength. Visit our page to explore our current
                    publisher partners who actively contribute to J-Gate.
                    <div>
                      <a
                        // href="https://jgateplus.com/search/footer-html/PublisherPartners.jsp"
                        href="/licencedpublisherlist"
                        target="_blank"
                        rel="noreferrer"
                        style={{
                          marginRight: "5px",
                          color: "#0d6efd",
                          textDecoration: "underline",
                          cursor:"pointer"
                        }}
                        //onClick={handleClick}
                      >
                        Click here 
                      </a>
                      
                      <span>
                        to see our current collaborating partners actively
                        participating in J-Gate.
                      </span>
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="5">
                  <div className="headingText headercolor">
                    J-Gate Infrastructure
                  </div>
                  <div className="subText">
                    <div>1. Accessible 24/7, 7 days a week. </div>
                    <div>
                      2. Utilizes high-speed, in-house internet infrastructure.
                    </div>
                    <div>
                      3. Employs a content capturing and conversion system with
                      high automation for top-quality content aggregation.
                    </div>
                    <div>
                      4. Features an in-house software development team.{" "}
                    </div>
                    <div>
                      5. Relies on a secure and dependable high-capacity storage
                      system for content storage.
                    </div>
                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="6">
                  <div className="headingText headercolor">Trial Access</div>
                  <div className="subText">
                    Informatics will be happy to set up trial access to J-Gate
                    and present the benefits of J-Gate customized services to
                    your users and decision makers in your campus. Please{" "}
                    <a
                      href="https://forms.office.com/Pages/ResponsePage.aspx?id=M_4_nylwpkuTMKIkglk7dh9jpA7zC89LgjVE64YxnDtUMjdYVUFKRVM3NFU5U0FJVU1ENzhOT0lZNy4u"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: "#0d6efd", textDecoration:"underline" }}

                    >
                      Click here
                    </a>{" "}
                    to request a trial.
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </div>
  );
};
